import React from 'react';
import ReactDOM from 'react-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import HTML5Backend from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { withTranslation, Trans } from 'react-i18next';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class TagTextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: this.props.tags,
      categoryTags: [],
      suggestions: [],
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  componentDidUpdate() {
    if (this.props.tags) {
      if(this.state.tags.length!=this.props.tags.length){
        this.setState({tags: this.props.tags});
      }
    }
  }

  handleDelete(i) {
    console.log('delete' ,i);
      const { tags } = this.state;
      this.props.updateCategories(tags.filter((tag, index) => index !== i));
  }

  handleAddition(tag) {
      this.setState(state => ({ tags: [...state.tags, tag] }));
  }

  handleDrag(tag, currPos, newPos) {
      const tags = [...this.state.tags];
      const newTags = tags.slice();

      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);

      // re-render
      this.setState({ tags: newTags });
  }

  render() {
    const { tags, suggestions } = this.state;
    const { t } = this.props;

    return (
      <div>
        <ReactTags
          allowDragDrop={ false }
          tags={ tags }
          suggestions={ suggestions }
          handleDelete={ this.handleDelete }
          handleAddition={ this.handleAddition }
          handleDrag={ this.handleDrag }
          delimiters={ delimiters }
          labelField="name"
          placeholder={ t('search_form.categories') }
        />
      </div>
    )
  }
};

export default withTranslation('translations')(TagTextField);
