import React from 'react';
import FacebookLoginWithButton from 'react-facebook-login';
import axios from './../../axios';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';

const componentClicked = () => {
  console.log("Clicked!")
}

const LoginButton = ({ facebookResponse }) => (
  <FacebookLoginWithButton
    appId="688427761991563"
    // autoLoad
    fields="name,email,picture"
    onClick={componentClicked}
    callback={facebookResponse}
    icon="fa-facebook"
    size="small"
    textButton="Login" />
)




class LoginFacebook extends React.Component {
  state = { user: false };

  facebookResponse = (response) => {
    this.setState({ ...this.state, user: response })

    localStorage.setItem('user_name',response.name);
    localStorage.setItem('user_email',response.email);

    const dataLogin = { accessToken: this.state.user.accessToken, userID: this.state.user.userID, user: response }
  

    this.props.facebookLogin(dataLogin);


  }

  render() {
    return (
      <div className="facebook-login">
      
          <LoginButton facebookResponse={this.facebookResponse} />
        
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    propIsLogin: state.isLogin
  }
}

const mapDispatchToProps = dispatch => {
  return {
    facebookLogin: (dataLogin) => dispatch(actionCreators.facebookLogin(dataLogin)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginFacebook);
