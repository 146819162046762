import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

// import logo from './assets/img/logo.gif';
// import './App.css';
import './App.scss';
import Header from './components/Header/Header.js';
import Footer from './components/Footer/Footer.js';

import LoginForm from './components/Login/LoginForm.js';
import RegisterForm from './components/Register/RegisterForm.js';
import HomePage from './components/HomePage/HomePage.js';
import MyProfile from './components/MyProfile/MyProfile.js';
import Favorites from './components/Favorites/Favorites.js';

import AddPoster from './components/Forms/Poster/AddPoster.js';
import EditPoster from './components/Forms/Poster/EditPoster.js';
import MyPosters from './components/Posters/MyPosters.js';
import Statistics from './components/Statistics/Statistics.js';
import PosterTemplate from './components/PosterTemplate/PosterTemplate.js';
import Mosaic from './components/Mosaic/Mosaic.js';

import AboutUs from './components/AboutUs/AboutUs.js';
import ForgotPassword from './components/ForgotPassword/ForgotPassword.js';
import Password from './components/ForgotPassword/Password.js';


// import { Router, useRouterHistory } from 'react-router';



//https://github.com/jossmac/react-images
class App extends Component {

  render() {

    return (

      <BrowserRouter>
      <div className="app__container">
        <Header />
        <Route path="/add-poster" exact component={AddPoster} />
        <Route path="/edit-poster" exact component={EditPoster} />
        <Route path="/my-posters" exact component={MyPosters} />
        <Route path="/statistics" exact component={Statistics} />
        <Route path="/template" exact component={PosterTemplate} />
        <Route path="/mosaic" exact component={Mosaic} />


        <Route path="/my-profile" exact component={MyProfile} />
        <Route path="/register" exact component={RegisterForm} />
        <Route path="/login" exact component={LoginForm} />
        <Route path="/forgot" exact component={ForgotPassword} />
        <Route path="/password" exact component={Password} />

        <Route path="/favorites" exact component={Favorites} />
        <Route path="/about-us" exact component={AboutUs} />

        <Route path="/" exact component={HomePage} />
        <Footer />

      </div>
      </BrowserRouter>
    );
  }
}

export default App;
