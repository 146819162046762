import React from "react";
import { withTranslation } from 'react-i18next';
import {Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import './SelectImage.css';


class SelectImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      selectTemplate: false,
      uploadedFile: null,
      selectedFile: null,
      loaded: null
    };
  }


  onChangeHandler=event=>{
    this.setState({
      selectedFile: event.target.files[0],
      uploadedFile: URL.createObjectURL(event.target.files[0]),
      loaded: 0
    })
  }

  createFromTemplate=()=>{
    this.props.createFromTemplate();
  }

  selectImage=(image, selectedFile)=>{
      console.log('click create from upload');
    this.props.selectImage(image, selectedFile);
  }


  render() {




    const { t } = this.props;
    if (this.props.propIsLogin !== true) {
      return (
          <Redirect to="/" />
      )
    }


    return (
      <div className="container select-image">

        <div>
          <label className="label-upload" htmlFor="upload-button">
                {this.state.uploadedFile ? (
                    <div>
                      <div>
                      <button onClick={() => this.selectImage(this.state.uploadedFile, this.state.selectedFile)} className="btn btn-viposter poster-button">
                        USE THIS IMAGE
                      </button>
                      </div>
                      <div className="uploaded-image">
                       <img className="uploaded-file" src={this.state.uploadedFile} alt="uploaded image" />
                      </div>
                  </div>
                ) : (
                <div className="btn btn-viposter poster-button">
                  {t('add_poster.upload_image')}
                </div>
                 )}
            </label>

            <input type="file" id="upload-button" name="file" style={{ display: "none" }} onChange={this.onChangeHandler}/>
        </div>

        <div className="middle-div">
          {t('add_poster.or')}
        </div>

        <div>
          <button onClick={this.createFromTemplate} className="btn btn-viposter poster-button">
          {t('add_poster.use_template')}
          </button>
        </div>


      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    propIsLogin: state.isLogin
    }
}

export default compose(withTranslation('translations'), connect(mapStateToProps))(SelectImage);
