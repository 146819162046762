import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import './LocationsModal.scss';

//https://github.com/jossmac/react-images
class LocationsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    }

    this.singleLocation = this.singleLocation.bind(this);
    this.allLocations = this.allLocations.bind(this);

  }

  singleLocation = (location) =>
    <div
      key={ location.locationId }
      className="locationsmodal__location"
      onClick={() => this.props.suggestionClicked(location) }
    >
      { location.label }
    </div>

  allLocations = (locations) => locations
    .map(location => this.singleLocation(location))

  render() {
    return (
      this.props.show ?
      <div className={"locationsmodal " + this.props.styleClass}>
          { this.allLocations(this.props.suggestions) }
        </div>
      : ''
    )
  }
}

const mapStateToProps = state => ( { propIsLogin: state.isLogin } )

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps)
)(LocationsModal);
