import React from 'react';
import './Mosaic.css';
import Posters from './Posters';
import Carousel from './Carousel';


class Mosaic extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
           
        }

        // this.onNextPage = this.onNextPage.bind(this)
        // this.onPrevPage = this.onPrevPage.bind(this)

      }

    //   shouldComponentUpdate(nextProps, nextState){
    //         if(nextProps.posters !== nextState.posters){
    //             return true;
    //         }  
    //         return false;
    //   }
    //  //

  

      static getDerivedStateFromProps(props, state){
        // const filtered = this.props.posters.slice(0, 20);
        // this.setState({posters: filtered});
          return props;
      }
  // const postersArray = [1]
  // const postersArray = [1, 2, 3, 4]


  // onNextPage(){
  //   this.props.onNextPage();
  // }

  // onPrevPage(){
  //   this.props.onPrevPage();
  // }


  render() {

  
  return (
    <div className="Mosaic">
      <Carousel row={ true }/>

    </div>
  );
  }
}

export default Mosaic;
