import React, { Component } from 'react';
import axios from './../../../axios';
import Autosuggest from 'react-autosuggest';
import './SuggestLocations.css';
import './../SearchForm.css';
import { withTranslation, Trans } from 'react-i18next';


class SuggestLocations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      suggestions: [],
      initialValue: '',
      checkerInitialValue: false
      };
  }

  componentDidMount(){
    if(this.props.initialValue){
      this.getLocations(this.props.initialValue);    
    }
  }

  componentDidUpdate(){
    if(this.props.initialValue){
      const sug = this.state.suggestions[0];
      if(sug){
          if(this.state.checkerInitialValue==false){
            this.setState({checkerInitialValue: true});
            this.setState({value: this.props.initialValue});
            this.props.onSubmit(sug.locationId);
        
        }
      }
    }
  }




  getLocations = (value) => {

    let location = value;
    const APIKEY = 'HBLU9uQBMdbJUJUpzYNFouJbBrLVM1lK5D-EmM8pq90';
    let AUTOCOMPLETION_URL = 'https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json';
console.log('get location 333', value);

    let query = '';
    let params = location.split(' ').join('+');
    if (params.length >= 1){
         params = '?' +
            'query='+params+
            '&maxresults=5' +
            // '&beginHighlight=<b>'+
            // '&endHighlight=</b>'+
            '&apikey=' + APIKEY;
    }

    axios.get(AUTOCOMPLETION_URL+params, query)
    .then(response => {
      this.setState({suggestions: response.data.suggestions})
    }

    );

  }


  escapeRegexCharacters(str) {
   return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
 }



  getSuggestionValue = (suggestion) => {
    this.props.locationId(suggestion.locationId);
    return suggestion.label;
 };

  renderSuggestion(suggestion) {
    console.log('render', suggestion.label);
   return (
     <span>{suggestion.label}</span>
   );
 }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.getLocations(value)
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { t, i18n } = this.props;


    const { value, suggestions } = this.state;
    const inputProps = {
      className: "form-control input-sm search-form-control",
      placeholder: t('search_form.type_location'),
      value,
      onChange: this.onChange
    };

    return (
      <Autosuggest
        className="autosuggestInput"
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps} 
       />
    );
  }
}

export default withTranslation('translations')(SuggestLocations);
