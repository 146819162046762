import React from "react";
import axios from './../../../axios';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.
import Autosuggest from 'react-autosuggest';



class AutocompleteTags extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        tags: this.props.tags,
        suggestions: []
      }
      this.autocompleteRenderInput = this.autocompleteRenderInput.bind(this);
      this.handleChange = this.handleChange.bind(this);

    }



  
    handleChange (tags) {
      console.log('zmiana tagow', tags);
      this.setState({tags})
      this.props.onChangeTags(tags);
    }
  

    autocompleteRenderInput ({addTag, ...props}) {
      const handleOnChange = (e, {newValue, method}) => {
        let value = '';

        if(newValue && newValue.length>0){
        //get all categories
        axios.get('/api/v1/tags/search/'+newValue)
        .then(response => {
          const suggestions = [];
          response.data.map(obj=> {
            suggestions[obj.id] = obj;
          })
          console.log('new suggestions', suggestions);
           this.setState({ suggestions: suggestions});
        });
      }


        if (method === 'enter') {
          e.preventDefault()
        } else {
          props.onChange(e)
        }
        
      }

      const inputValue = (props.value && props.value.trim().toLowerCase()) || ''
      const inputLength = inputValue.length
      
      // let suggestions = states().filter((state) => {
      //   return state.name.toLowerCase().slice(0, inputLength) === inputValue
      // })

  
      return (  
        <Autosuggest
          ref={props.ref}
          suggestions={this.state.suggestions}
          shouldRenderSuggestions={(value) => value && value.trim().length > 0 && this.state.suggestions.length>0}
          getSuggestionValue={(suggestion) => suggestion ? suggestion.name : ''}
          renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
          inputProps={{...props, onChange: handleOnChange}}
          onSuggestionSelected={(e, {suggestion}) => {
            addTag(suggestion.name)
          }}
          onSuggestionsClearRequested={() => {}}
          onSuggestionsFetchRequested={() => {}}
        />
      )
    }


    render () {

      return <TagsInput renderInput={this.autocompleteRenderInput} value={this.state.tags} onChange={this.handleChange} />
    }
  }
  
  export default AutocompleteTags