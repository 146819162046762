import React from "react";
import axios from './../../../axios';
import './AddPoster.css';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './../ReactTags.css';
import { withTranslation } from 'react-i18next';
import SuggestLocations from './../Inputs/SuggestLocations.js';
import SweetAlert from 'sweetalert2-react';
import {Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {pl, enUS} from 'date-fns/locale';
import i18n from './../../../containers/i18n';
import Categories from './../Inputs/Categories';
import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.
import AutocompleteTags from './AutocompleteTags';

class EditPoster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      uploadedFile: null,
      dateRange: {
        startDate: new Date(),
        endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        key: 'selection'
      },
      categories: [],
      categoryTags: [],
      latitude: null,
      longitude: null,
      address: {},
      fullAddress: null,
      name: '',
      redirectUrl: '',
      sweetalert: {
        show: false,
        title: null,
        message: null
      },
      fromOnePoster: null,
      posterId: null,
      tags: [],
      tagsSuggestions: []
    };

    this.handleChange = this.handleChange.bind(this);


  }


  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
}

  UNSAFE_componentWillMount(){

    console.log(this.props.location.state);

    //get all categories
    axios.get('/api/v1/categories')
    .then(response => {
      console.log(response);
       const categories = [];
      response.data.map(obj=> {
        categories[obj.id] = obj;
      })
      //update categories from my-posters page
      if(this.props.location.state){
        const setCategories = [];
        this.props.location.state.fromOnePoster.categories.map(obj=> {
          setCategories[obj.id] = categories[obj.id];
        })

        this.setState({ categoryTags: setCategories});
      }

       this.setState({ categories: categories});
    });

    let tags = [];
    this.props.location.state.fromOnePoster.tags.map(obj=> {
      tags.push(obj.name);
    })
    console.log('tagi will mount: ', tags);
    this.setState({tags});



  }

  submitFormHandler = () => {

    const searchedCategories = [];
    this.state.categoryTags.map(obj=> {
      searchedCategories.push(obj.id);
    })

    console.log('BEFORE SENDING', this.state);

    const formData = new FormData();
    formData.append('date_start', this.state.dateRange.startDate);
    formData.append('date_end', this.state.dateRange.endDate);

    formData.append('categories', searchedCategories);
    formData.append('language', this.props.i18n.languages[0]);
    if(this.state.latitude) formData.append('latitude', this.state.latitude);
    if(this.state.longitude) formData.append('longitude', this.state.longitude);
    if(this.state.name) formData.append('name', this.state.name);
    if(this.state.redirectUrl) formData.append('redirect_url', this.state.redirectUrl);

    if( this.state.selectedFile) formData.append('image', this.state.selectedFile);

    if( this.state.address.country) formData.append('country', this.state.address.country);
    if( this.state.address.city) formData.append('city', this.state.address.city);
    if( this.state.address.state) formData.append('state', this.state.address.state);
    if( this.state.address.postalCode) formData.append('postal_code', this.state.address.postalCode);
    if( this.state.address.street) formData.append('street', this.state.address.street);
    if( this.state.address.houseNumber) formData.append('house_number', this.state.address.houseNumber);
    if( this.state.address.label) formData.append('full_address', this.state.address.label);
    if( this.state.tags) formData.append('tags', this.state.tags);



    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
    };

    const posterId = this.props.location.state.fromOnePoster.id;
    axios.post('/api/v1/poster/'+posterId, formData, config)
    .then(response => {
      console.log('okdd', response);

      let sweetalert = {...this.state.sweetalert}

      sweetalert.message=i18n.t('edit_poster.poster_has_been_updated');
      sweetalert.title=i18n.t('global.success');
      sweetalert.show=true;
      this.setState({sweetalert: sweetalert});


    }).catch(error => {

      let sweetalert = {...this.state.sweetalert}

      if(error.request.response){
        const parsedError = JSON.parse(error.request.response);

         let errors = parsedError.error;
        for (var key in errors) {
          if (!errors.hasOwnProperty(key)) continue;
           sweetalert.message=errors[key][0];
            break;
        }
      } else {
        sweetalert.message=i18n.t('global.unknown_error');
      }
      sweetalert.title=i18n.t('global.error');

      sweetalert.show = true;

      this.setState({sweetalert})

    });


  }

  onChangeHandler=event=>{
    this.setState({
      selectedFile: event.target.files[0],
      uploadedFile: URL.createObjectURL(event.target.files[0]),
      loaded: 0,
    })
  }


  handleChangeTags = (tags) => {
    this.setState({tags});
  }

  onChangeFunction = (newVal, oldVal) => {
    console.log('Changed from', oldVal, ' to ', newVal);
  }


handleLocation = (event) => {

  let location = event.target.value;
  const APIKEY = 'HBLU9uQBMdbJUJUpzYNFouJbBrLVM1lK5D-EmM8pq90';
  let AUTOCOMPLETION_URL = 'https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json';


  let query = '';
  let params = location.split(' ').join('+');
  if (params.length >= 1){
       params = '?' +
          'query='+params+
          '&maxresults=5' +
          '&apikey=' + APIKEY;
  }

axios.get(AUTOCOMPLETION_URL+params, query)
  .then(response => {
    this.setState({suggestions: response.data.suggestions})
  });
}

onSubmit = (location) => {
  console.log('location: ',location);
}

getCoordinates = (locationId) => {

  const APIKEY = 'HBLU9uQBMdbJUJUpzYNFouJbBrLVM1lK5D-EmM8pq90';

  let URL = 'https://geocoder.ls.hereapi.com/6.2/geocode.json';
  let params = '?locationid=' + locationId + '&jsonattributes=1&gen=9&apiKey=' + APIKEY;


  axios.get(URL+params)
  .then(response  => {
    let displayPosition = response.data.response.view[0].result[0].location.displayPosition;
    this.setState({address: response.data.response.view[0].result[0].location.address});
    console.log(this.state.address);
    this.setState({latitude: displayPosition.latitude, longitude: displayPosition.longitude});
  });
}


handleLocationSuggestions = (locationId) => {
  this.getCoordinates(locationId);
}

handleSuggestion = (locationId) => {
  this.getCoordinates(locationId);
}

onChangeCategories = (event, value) => {
  this.setState({ categoryTags: value });
  console.log('category tags', this.state.categoryTags);
}


componentDidUpdate(prevProps, prevState, snapshot){

  const initialPoster = this.props.location.state.fromOnePoster;

  if(initialPoster.id !== this.state.posterId) {

    if(initialPoster.date_start){
      if(!initialPoster.date_end){
        initialPoster.date_end = initialPoster.date_start;
      }

    this.setState({posterId: initialPoster.id})
    const dateRange = {
      startDate: new Date(initialPoster.date_start),
      endDate: new Date(initialPoster.date_end),
      key: 'selection' }
      this.setState({dateRange: dateRange});
    }


    this.setState({name: initialPoster.name});
    this.setState({redirectUrl: initialPoster.redirectURL});

  }

  return false;
}


  render() {

    const { t, i18n } = this.props;
    const {fromOnePoster} = this.props.location.state;

    if (this.props.propIsLogin !== true) {
      return (
          <Redirect to="/" />
      )
    }


    if (!Number.isInteger(fromOnePoster.id)) {
      return (
          <Redirect to="/my-posters" />
      )
    }

    // const categories = [];
    // fromOnePoster.categories.map(obj=> {
    //   categories[obj.id] = obj;
    // })




    return (
        <div className="container">
            <div className="h2-title">
              <h2> {t('edit_poster.edit_your_poster')}</h2>
            </div>
            <div className="add-poster-form">
            <div className="add-poster-column">
              <div className="add-poster-row">
              <div className="row-left">
              {t('edit_poster.name')}:
              </div>
              <div className="row-right">
              <input className="form-control input-sm" type="text" name="name" placeholder={t('edit_poster.name')} value={this.state.name} onChange={this.handleChange} />
              </div>
              <div className="row-left">
              {t('edit_poster.location')}:
              </div>
              <div className="row-right show-suggestions">
              <SuggestLocations locationId={this.handleLocationSuggestions} onSubmit={this.handleSuggestion} initialValue={fromOnePoster.location ? fromOnePoster.location[0].fullAddress : null} />
              </div>
              <div className="row-left">
              {t('edit_poster.redirect_url')}:
              </div>
              <div className="row-right">
              <input className="form-control input-sm" type="text" name="redirectUrl" placeholder={t('edit_poster.redirect_url')} value={this.state.redirectUrl} onChange={this.handleChange} />
              </div>
              <div className="row-left">
               {t('edit_poster.upload_image')}:
              </div>
              <div className="row-right">

              <label htmlFor="upload-button">

                { this.state.uploadedFile ? (
                  <img className="uploaded-file" src={this.state.uploadedFile} alt="uploaded image" />
                ) : (
                  <img className="uploaded-file" src={fromOnePoster.thumbnail} alt="uploaded image" />
                 )}
             </label>

              <input type="file" id="upload-button" name="file" style={{ display: "none" }} onChange={this.onChangeHandler}/>

              </div>
              <div className="row-left">
              {t('edit_poster.categories')}:
              </div>
              <div className="row-right">
              <Categories value={this.state.categoryTags} categories={this.state.categories} language={i18n.languages[0]} handleChangeAutocomplete={this.onChangeCategories} />
              </div>
              <div className="row-left">
              {t('edit_poster.tags')}:
              </div>
              <div className="row-right">
              <AutocompleteTags onChangeTags={this.handleChangeTags} tags={this.state.tags}  />
              </div>

         </div>
        </div>
            <div className="add-poster-column">
              <DateRange
                editableDateInputs={true}
                onChange={item => this.setState({dateRange: item.selection})}
                moveRangeOnFirstSelection={false}
                ranges={[this.state.dateRange]}
                format={'Y-MM-dd H:i:s'}
                locale={ i18n.languages[0]=='pl' ? pl : enUS }
              />
            </div>
          </div>
        <div className="upload-section">
            <button type="button" className="btn btn-viposter btn-block upload-button" onClick={this.submitFormHandler}>{t('edit_poster.update')}</button>
        </div>


        <SweetAlert
        show={this.state.sweetalert.show}
        title={this.state.sweetalert.title}
        text={this.state.sweetalert.message}
        onConfirm={() => this.setState({sweetalert: {show: false }})}
      />

        </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    propIsLogin: state.isLogin
    }
}

export default compose(withTranslation('translations'), connect(mapStateToProps))(EditPoster);
