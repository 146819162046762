import React, { Component } from 'react';
import * as Icon from 'react-bootstrap-icons';
import axios from './../../axios';
import * as actionCreators from '../../store/actions/actions';
import { connect } from 'react-redux';



class FavoriteIcon extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isHovered: false,
            isFavorite: this.props.propFavoritesPosters.includes(this.props.image.id)
        };
      }

      

    handleFavoritePoster(event){

        let favoriteState = !this.props.propFavoritesPosters.includes(this.props.image.id);

        const dataPoster = {
            poster_id: this.props.image.id,
            is_favorite: favoriteState
        }

        this.props.handleFavoritesPosters(dataPoster);

        event.preventDefault();

    }


    render() {

        let favIcon = '';
        if(this.props.propFavoritesPosters.includes(this.props.image.id)){
           if(this.state.isHovered){
                favIcon = <Icon.Star color="#8b49f6" size={30} />;
            } else {
                favIcon = <Icon.StarFill color="#8b49f6" size={30} />;
            }
        } else {
            if(this.state.isHovered){
                favIcon = <Icon.StarFill color="#8b49f6" size={30} />;
            } else {
                favIcon = <Icon.Star color="#8b49f6" size={30} />;
            }     
        }
        
        return (
            <div className='icon' 
            onMouseEnter={() => this.setState({isHovered: true})}
            onMouseLeave={() => this.setState({isHovered: false})}
            onClick={this.handleFavoritePoster.bind(this)}
            >

            {favIcon}

            </div>
      )
  }
}
  


const mapStateToProps = state => {
    return {
      propFavoritesPosters: state.favoritesPosters
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      handleFavoritesPosters: (posterData) => dispatch(actionCreators.manageFavoritesPosters(posterData)),
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(FavoriteIcon);

  
