import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
// import * as Icon from 'react-bootstrap-icons';
import FavoriteIcon from './FavoriteIcon';
import LikesIcon from './LikesIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp as fasThumbsUp } from '@fortawesome/fontawesome-free-regular';
import SocialMediaButtons from './SocialMediaButtons';
import './ModalPoster.css';

class ModalPoster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.isOpen,
      displayedImage: this.props.displayedImage,
      posterIndex: this.props.posterIndex
    };
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.isOpen != prevState.isOpen || this.props.isOpen != prevProps.isOpen){
      this.setState({isOpen: this.props.isOpen, displayedImage: this.props.displayedImage});
    }
  }




  // handleFavorite = (data) => {
  //   this.props.onSubmitFavorite(data);
  // }

  handleLikes = (data) => {
    this.props.onSubmitLikes(data);
  }
 

  hideModal = () => {
    this.setState({isOpen: false});
  };

  handleOpenImageModal = (image) => {
    this.setState({displayedImage: image});
    this.setState({isOpen: true});

  };

  
  render() {
    return(

      <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={this.state.isOpen}
      onHide={this.hideModal}>
      <Modal.Header closeButton>
        <div className="subheader">
          {this.state.displayedImage.name ? (
              <Modal.Title>

                  {this.state.displayedImage.redirectURL ? (
                
                    <a target="_blank" rel="noreferrer" href={this.state.displayedImage.redirectURL}>{this.state.displayedImage.name}</a>
                  
                ) : this.state.displayedImage.name}
                
                </Modal.Title>
            ) : ''}

    
            <div className="left-icons">
              {this.props.propIsLogin ?  <FavoriteIcon  image={this.state.displayedImage} /> : '' }
              {this.props.propIsLogin ?  <LikesIcon  onSubmit={this.handleLikes}  image={this.state.displayedImage} posterIndex={this.props.posterIndex}  /> : '' }
              <SocialMediaButtons poster={this.state.displayedImage}/>
            </div>

        </div>


      </Modal.Header>
      <Modal.Body>
          <img className="img-fluid" src={this.state.displayedImage.image} alt={this.state.displayedImage.image} />
      </Modal.Body>

    </Modal>
    )
  }
}

export default ModalPoster;