import React, { Component, Fragment } from "react";
import axios from "./../../axios";
import SuggestLocations from "./Inputs/SuggestLocations.js";
import "./SearchForm.css";
import loupe from "../../assets/img/header/loupe.svg";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/actions";
import { compose } from "redux";
import {
  CalendarModal,
  CategoryModal,
  LocationsModal,
  TagsModal,
} from "./Modals";
import CategoryTextField from "./CategoryTextField";
import TagTextField from "./TagTextField";

// import onClickOutside from "react-onclickoutside";
import "./SearchForm.scss";
import AutocompleteTags from "./AutocompleteTags";

class SearchForm extends Component {
  // handleClickOutside = (evt) => {
  //   this.setState({ show: false });
  //   this.setState({ categoryShow: false });
  //   this.setState({ showMobileModal: false });
  // };

  constructor(props) {
    super(props);
    this.state = {
      images: [],
      isUserGeolocation: true,
      tag: "",
      date: "",
      latitude: null,
      longitude: null,
      locationSuggestions: [],
      locationId: null,
      location: {},
      sortBy: "distance",
      categories: [],
      category_tags: [],
      categoryTextFieldTags: [],
      activeIndexFlag: "",
      foundImages: null,
      coordinates: {
        latitude: null,
        longitude: null,
      },
      addressLabel: "",
      updatedLikes: null,
      // dateRange: {
      //   startDate: new Date(),
      //   endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      //   key: 'selection'
      // },
      dateRange: {
        startDate: null,
        endDate: null,
        key: "selection",
      },
      categoryShow: false,
      calendarShow: false,
      locationsShow: false,
      showModal: false,
      showMobileModal: false,
      tags: [],
      clearForm: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTags = this.handleTags.bind(this);
    this.refMobileModal = React.createRef();

    this.onChangeCategories = this.onChangeCategories.bind(this);
    this.updateCategories = this.updateCategories.bind(this);
  }



  UNSAFE_componentWillMount() {
    //get all categories
    axios.get("/api/v1/categories-with-structure").then((response) => {
      const categories = [];
      response.data.map((obj) => {
        categories.push(obj);
        // categories[obj.id] = obj;
      });
      this.setState({ categories: categories });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.updatedLikes) {
      const posterIndex = this.state.images.findIndex(
        (item) => item.id === this.props.updatedLikes.posterId
      );

      if (this.state.images[posterIndex]) {
        if (
          this.state.images[posterIndex].likes !== this.props.updatedLikes.likes
        ) {
          this.state.images[posterIndex].likes = this.props.updatedLikes.likes;
          this.props.onSubmit(this.state.images);
        }
      }
    }
    // console.log('here 11');

    // //reset filters
    // if(this.props.clearForm==true && (prevProps.clearForm != this.props.clearForm)){
    //   this.props.clearFilters(false);
    //   // this.handleTags([]);
    //   console.log('here 22');

    //   let locationObject = { label: "" };

    //   this.setState({
    //     dateRange: {dateStart: null, dateEnd: null},
    //     tags: [],
    //     locationSuggestions: [],
    //     coordinates: {
    //       latitude: null,
    //       longitude: null,
    //     },
    //     addressLabel: "",
    //     city: null,
    //     latitude: null,
    //     longitude: null,
    //     sort: "distance",
    //     category_tags: [],
    //     categoryTextFieldTags: [],
    //     locationId: null,
    //     location: locationObject,
    //     clearForm: false
    //   });

    //   console.log('here 33');

     

      

    //   // const dataSearch = {
    //   //   dateStart: null,
    //   //   dateEnd: null,
    //   //   tags: [],
    //   //   city: null,
    //   //   latitude: null,
    //   //   longitude: null,
    //   //   sort: "distance",
    //   //   categories: []
    //   // };

    //   // this.props.searchPosters(dataSearch, 1, this.state.activeIndexFlag, true);
    // } 

    if (
      prevState.tags.length != this.state.tags.length ||
      prevState.latitude !== this.state.latitude ||
      prevState.dateRange.startDate !== this.state.dateRange.startDate ||
      prevState.dateRange.endDate !== this.state.dateRange.endDate ||
      prevState.category_tags.length != this.state.category_tags.length
    ) {
      const searchedCategories = [];
      this.state.category_tags.map((obj) => {
        searchedCategories.push(obj.id);
      });

      const dataSearch = {
        dateStart: this.state.dateRange.startDate,
        dateEnd: this.state.dateRange.endDate,
        tags: this.state.tags,
        city: this.state.city,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        sort: this.state.sortBy,
        categories: searchedCategories,
      };

      //get new posters
      this.props.searchPosters(dataSearch, 1, this.state.activeIndexFlag, true);
    }

    if (this.props.page > prevProps.page) {
      const searchedCategories = [];
      this.state.category_tags.map((obj) => {
        searchedCategories.push(obj.id);
      });

      const dataSearch = {
        dateStart: this.state.dateRange.startDate,
        dateEnd: this.state.dateRange.endDate,
        tags: this.state.tags,
        city: this.state.city,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        sort: this.state.sortBy,
        categories: searchedCategories,
      };

      //get new posters
      this.props.searchPosters(
        dataSearch,
        this.props.page,
        this.state.activeIndexFlag,
        false
      );
    }

    if (this.props.coordinates && this.state.isUserGeolocation === true) {
      if (this.state.latitude !== this.props.coordinates.latitude) {
        this.state.longitude = this.props.coordinates.longitude;
        this.state.latitude = this.props.coordinates.latitude;
        this.setState({
          isUserGeolocation: false,
        });

        this.state.date = new Date();
        this.handleSubmit();
      }
    }
  }

  getCoordinates = (locationId) => {
    const APIKEY = "HBLU9uQBMdbJUJUpzYNFouJbBrLVM1lK5D-EmM8pq90";
    let URL = "https://geocoder.ls.hereapi.com/6.2/geocode.json";
    let params =
      "?locationid=" + locationId + "&jsonattributes=1&gen=9&apiKey=" + APIKEY;

    axios.get(URL + params).then((response) => {
      let displayPosition =
        response.data.response.view[0].result[0].location.displayPosition;
      this.setState({
        latitude: displayPosition.latitude,
        longitude: displayPosition.longitude,
      });
    });
  };

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    const searchedCategories = [];
    this.state.category_tags.map((obj) => {
      searchedCategories.push(obj.id);
    });

    const dataSearch = {
      dateStart: this.state.dateRange.startDate,
      dateEnd: this.state.dateRange.endDate,
      name: this.state.name,
      city: this.state.city,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      sort: this.state.sortBy,
      categories: searchedCategories,
    };
    this.props.searchPosters(dataSearch, 1, this.state.activeIndexFlag, true);
  }

  handleTags = (tags) => {
    this.setState({ ...this.state, tags });
  };


  // handleCalendar = (date) => this.setState({ date });

  handleLocationId = (location) => {
    this.setState({ addressLabel: location.label });
    this.setState({ location: location });

    this.getCoordinates(location.locationId);
    console.log('handle location id');
    // this.hideMobileModal();
  };
  handleLocationSuggestions = (locationSuggestions) => {
    if (locationSuggestions.length) {
      this.setState({ locationsShow: true });
    }
    this.setState({ locationSuggestions: locationSuggestions });
  };

  //categories icon clicked in category modal
  onChangeCategories = (category) => {
    //prevent to duplicates in array
    const categoryIndex = this.state.category_tags.findIndex(
      (item) => item.id == category.id
    );
    if (categoryIndex >= 0) {
      return null;
    }
    category.id = category.id.toString();

    this.setState({ category_tags: [...this.state.category_tags, category] });
  };

  updateCategories = (categories) => {
    this.setState({ category_tags: categories });
  };

  updateTags = (tags) => {
    this.setState({ tags: tags });
  };

  toggleMobileModal = (modalName) => {
    if (this.state.showMobileModal === modalName) {
      this.hideMobileModal();
    } else {
      this.setState({ showMobileModal: modalName });
    }
  };

  hideMobileModal = () => this.setState({ showMobileModal: false });

  toggleModal = (modalName) => {
    if (this.state.showModal === modalName) {
      this.hideModal();
    } else {
      this.setState({ showModal: modalName });
    }
  };

  hideModal = () => this.setState({ showModal: false });

  changeDateRange = (date) => this.setState({ dateRange: date });

  formatDate = (date) => {
    let year = date.getFullYear();
    year = year.toString().slice(2);
    let month = date.getMonth() + 1;
    month = month < 10 ? (month = "0" + month) : month;
    let day = date.getDate();
    day = day < 10 ? (day = "0" + day) : day;

    let convertedDate = month + "/" + day + "/" + year;
    return convertedDate;
  };

  
  //if someone click outside mobile modal -  hide it
  handleClickOutside(event) {
    if (this.refMobileModal.current && !this.refMobileModal.current.contains(event.target)) {
      this.setState({ showMobileModal : false });
    }
   
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside.bind(this), true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside.bind(this), true);
  };


 

  render() {

    
    const { t } = this.props;
    const dateRange = this.state.dateRange;
    const dateInputValue = () =>
      dateRange.startDate
        ? `${this.formatDate(dateRange.startDate)} - ${this.formatDate(
            dateRange.endDate
          )}`
        : "";

    const modalSwitch = (modalName) => (
      <Fragment>
       <div className="show-desktop">
        <div
          className="topsearch__modalbackdrop"
          onClick={() => this.hideModal()}
        ></div>

        <div className="topsearch__modal">
          <TagsModal
            show={modalName === "tags"}
            onSubmit={this.handleTags}
            tags={this.state.tags}
          />
          <LocationsModal
            show={
              modalName === "location" && this.state.locationSuggestions.length
            }
            // show={modalName === 'location'}
            suggestions={this.state.locationSuggestions}
            suggestionClicked={this.handleLocationId}
          />
          <CalendarModal
            changeDateRange={this.changeDateRange}
            dateRange={this.state.dateRange}
            // onSubmit={this.handleCalendar}
            show={modalName === "calendar"}
            handleClose={this.hideCalendarModal}
          />
          <CategoryModal
            onHandleCategory={this.onChangeCategories}
            categories={this.state.categories}
            handleClose={this.hideCategoryModal}
            show={modalName === "category"}
          />
        </div>
        </div>
      </Fragment>
    );

    const isSelected = (modalName) =>
      this.state.showModal === modalName ? "selected" : "";

    const isMobileSelected = (modalName) =>
      this.state.showMobileModal === modalName ? "mobile-selected" : "";

    return (
      <nav className="topsearch">
        <div className="topsearch__items show-desktop">
          <div
            className={"topsearch__main topsearch__select" + isSelected("tags")}
            onClick={() => this.toggleModal("tags")}
          >
            <AutocompleteTags
              onChangeTags={this.handleTags}
              tags={this.state.tags}
            />
          </div>

          <div
            className={
              "topsearch__main topsearch__select header-location " +
              isSelected("location")
            }
            onClick={() => this.toggleModal("location")}
          >
            <SuggestLocations
              locationSuggestions={this.handleLocationSuggestions}
              locationId={this.handleLocationId}
              location={this.state.location}
            />
          </div>
          <div
            className={
              "topsearch__main topsearch__select " + isSelected("calendar")
            }
          >
            <input
              type="text"
              name="date"
              placeholder={t("search_form.date")}
              value={dateInputValue()}
              readOnly
              onClick={() => this.toggleModal("calendar")}
            />
          </div>
          <div
            className={
              "topsearch__main topsearch__select " + isSelected("category")
            }
            onClick={() => this.toggleModal("category")}
          >
            <CategoryTextField
              updateCategories={this.updateCategories}
              tags={this.state.category_tags}
            />
          </div>
          <div
            className="topsearch__main topsearch__button"
            onClick={this.handleSubmit}
          >
            <img className="topsearch__icon" src={loupe} />
          </div>
          {/* {this.state.showModal ? modalSwitch(this.state.showModal) : ""} */}
        </div>

        {/* <div className="show-tablet topsearch__items">
          show tablet dd ddd
        </div> */}

        <div className="show-mobile topsearch__items">
          <div className="topsearch__mobilebar">
            <div className="mobile_icons">
              <button className={"mobile_button mobile_button__tag-button "+isMobileSelected('mobile_tags')} onClick={() => this.toggleMobileModal("mobile_tags")}>
              {t("search_form.tag")}
              </button>
              <button className={"mobile_button mobile_button__location-button "+isMobileSelected('mobile_location')} onClick={() => this.toggleMobileModal("mobile_location")}>
              {t("search_form.location")}
              </button>
              <button className={"mobile_button mobile_button__date-button "+isMobileSelected('mobile_date')} onClick={() => this.toggleMobileModal("mobile_date")}>
              {t("search_form.date")}
              </button>
              <button className={"mobile_button mobile_button__category-button "+isMobileSelected('mobile_category')} onClick={() => this.toggleMobileModal("mobile_category")}>
              {t("search_form.categories")}
              </button>
            </div>

          <div ref={this.refMobileModal}>
          {this.state.showMobileModal == "mobile_tags" ? (
              <div className="mobile_tags">
                <AutocompleteTags 
                  onChangeTags={this.handleTags}
                  tags={this.state.tags}
                />
                <TagsModal
                  styleClass={"mobile"}
                  show={true}
                  onSubmit={this.handleTags}
                  tags={this.state.tags}
                />
              </div>
            ) : (
              ""
            )}

            {this.state.showMobileModal == "mobile_location" ? (
              <div>
                <SuggestLocations
                 key="2"
                 id="2"
                  styleClass={"mobile"}
                  locationSuggestions={this.handleLocationSuggestions}
                  locationId={this.handleLocationId}
                  location={this.state.location}
                />

                <LocationsModal
                  styleClass={"mobile"}
                  show={this.state.locationSuggestions.length}
                  suggestions={this.state.locationSuggestions}
                  suggestionClicked={this.handleLocationId}
                />
              </div>
            ) : (
              ""
            )}

            {this.state.showMobileModal == "mobile_date" ? (
              <div>

                <CalendarModal
                  styleClass={"mobile"}
                  changeDateRange={this.changeDateRange}
                  dateRange={this.state.dateRange}
                  // onSubmit={this.handleCalendar}
                  show={true}
                  handleClose={this.hideCalendarModal}
                />
              </div>
            ) : (
              ""
            )}

            {this.state.showMobileModal == "mobile_category" ? (
              <div>
                <CategoryTextField
                  updateCategories={this.updateCategories}
                  tags={this.state.category_tags}
                />

                <CategoryModal
                  id="2"
                  styleClass={"mobile"}
                  onHandleCategory={this.onChangeCategories}
                  categories={this.state.categories}
                  handleClose={this.hideCategoryModal}
                  show={true}
                />
              </div>
            ) : (
              ""
            )}
          </div>


         
          </div>
        </div>

        {this.state.showModal ? modalSwitch(this.state.showModal) : ""}
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    activeIndex: state.activeIndex,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPage: (page) => dispatch(actionCreators.setPage(page)),
    searchPosters: (data, page, activeIndexFlag, newSearch) =>
      dispatch(
        actionCreators.searchPosters(data, page, activeIndexFlag, newSearch)
      ),
  };
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps)
)(SearchForm);
