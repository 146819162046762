import React, { Component } from "react";
import "./Header.scss";
import logo from "./../../assets/img/viposterLogo_lightBG.svg";

import { Link } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";
import SearchForm from "./SearchForm";
import AddPoster from "./HeaderButtons/AddPoster";
import ToggleLanguage from "./HeaderButtons/ToggleLanguage";
import UserMenu from "./HeaderButtons/UserMenu";
import MobileMenu from "./HeaderButtons/MobileMenu";
import Geolocation from "./../../components/Geolocation.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt as faTrash } from '@fortawesome/fontawesome-free-regular';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foundImages: null,
      coordinates: {
        latitude: null,
        longitude: null,
      },
      updatedLikes: null,
      clearForm: false
    };
    //  this.onNextPage = this.onNextPage.bind(this)
    //  this.onPrevPage = this.onPrevPage.bind(this)
  }

  foundImages = (images) => {
    this.setState({ foundImages: images });
  };

  clearFilters = (clearFilter) => {
    this.setState({ clearForm: clearFilter });
  };

  setCoordinates = (coordinates) => {
    this.setState({ coordinates: coordinates });
  };

  //when user click on favorite button - change it in the state
  // handleFavorite = (data) => {
  //   this.state.foundImages[data.posterId].is_favorite = data.favoriteState;
  //   this.forceUpdate()
  // }

  handleLikes = (data) => {
    const posterIndex = this.state.foundImages.findIndex(
      (item) => item.id === data.posterId
    );
    // this.useState(foundImages[posterIndex].likes = data.likes);
    this.state.foundImages[posterIndex].likes = data.likes;
    this.forceUpdate();
    this.updateGlobalLikes(data);
  };

  clearSearchForm = () => {
    this.setState({ clearForm: true });
  }

  updateGlobalLikes = (data) => {
    this.setState({ updatedLikes: data });
  };

  render() {
    const createTopColors = () => [0, 1, 2, 3].map((i) => <div key={i}></div>);

    return (
      <header className="appheader">
        <Geolocation onSubmit={this.setCoordinates} />
        <div className="topcolors">{createTopColors()}</div>
        <div className="logo__container">
          <Link className="logo__image" to="/">
            <img src={logo} alt="Viposter" />
          </Link>
        </div>
        <SearchForm
          coordinates={this.state.coordinates}
          updatedLikes={this.state.updatedLikes}
          onSubmit={this.foundImages}
          clearForm={this.state.clearForm}
          clearFilters={this.clearFilters}
        />

        <div className="appheader__icons">
          <span className="show-desktop">
            <FontAwesomeIcon onClick={this.clearSearchForm} icon={faTrash}  color="rgba(254, 29, 91, 1)" size="1x"  />
          </span>
          <span className="show-desktop">
            <AddPoster />
          </span>
          <span className="show-desktop">
            <ToggleLanguage />
          </span>
          <span className="show-desktop">
            <UserMenu />
          </span>
        </div>

        <span className="show-mobile">
          <MobileMenu />
        </span>
        {/* <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button> */}
        {/* <Login/> */}
      </header>
    );
  }
}

export default withTranslation("translations")(Header);
