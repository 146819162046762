import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import addActive from '../../../assets/img/header/add_active.svg';
import addInactive from '../../../assets/img/header/add_inactive.svg';
import './AddPoster.scss';
import {Route,Link, Redirect, withRouter} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

class AddPoster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    }
  }

  render() {
    return (
      <div>
        <ReactTooltip />
        {
          this.props.propIsLogin
            ?
            <Link to='/add-poster'>
              <div data-tip="add your poster"  className="addposter">
                  <img className="headericon" alt="image" src={ addActive } />
              </div>
            </Link>
            :
            <div data-tip="Login to add your poster" className="addposter">
              <img className="headericon" alt="image" src={ addInactive } />
            </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({ propIsLogin: state.isLogin })

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps))(
withRouter(AddPoster));


// export default withTranslation('translations')(withRouter)(MenuLoggedUser);
