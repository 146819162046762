import React from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import axios from './../../axios';
import FormErrors from './../../components/FormErrors.js';
import './ForgotPassword.css';
import {Route,Link, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';



class ForgotPassword extends React.Component
{

    
    constructor(props)
    {
        super(props);
        this.state =
        {
          name: null,
          email: '',
          password: null,
          c_password: null,
          validated: false,
          success: '',
          message: '',
          formErrors: {name: '', email: '', password: '', c_password: ''},
          emailValid: false,
          passwordValid: false,
          confirmPasswordValid: false,
          formValid: false,
          passwordError: '',
          isOpen: false,
          formRegisterError: null,
          sweetalert: {
            show: false,
            title: null,
            message: null
          },
          sweetalert_success: {
            show: false,
            title: null,
            message: null
          },
          sweetalert_error: {
            show: false,
            title: null,
            message: null
          }
        };


        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    clearFormErrors() {
      this.setState({formErrors: {name: '', email: '', password: '', c_password: ''}});
    }



    handleSubmit(event)
    {
           event.preventDefault();
           event.stopPropagation();

            const data = {
              email: this.state.email
            }

            axios.post('/api/user/forgot', data)
            .then(response => {
              let sweetalert = {...this.state.sweetalert_success}
      
              sweetalert.message="Email has been sent";
              sweetalert.title="SUCCESS";
              sweetalert.show=true;
              this.setState({sweetalert_success: sweetalert});
                    
            }).catch((error) => {

              // console.log(error.request.response);
              let sweetalert = {...this.state.sweetalert_error}

              if(error.request.response){
                const parsedError = JSON.parse(error.request.response);

                let errors = parsedError.errors;

                for (var key in errors) {
                  if (!errors.hasOwnProperty(key)) continue;
                    sweetalert.message=errors[key][0];
                    break;
                }
              } else {
                sweetalert.message="Unknown error";
              }
              sweetalert.title = "ERROR";
              sweetalert.show = true;
        
              this.setState({sweetalert_error: sweetalert})
            });
        
    }


    handleChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      this.setState({
        [name]: value
      });
  }

   hideModal() {
    this.setState({isOpen: false});
  };

  showErrors = (errors) => {

    for (var key in errors) {
        if (!errors.hasOwnProperty(key)) continue;
        return (
          <p>{errors[key][0]}</p>
        )
    }
  
  }

    checkRequired(value) {
        return !!value.trim();
    }


    render()
    {
      const { t } = this.props;
      if (this.props.propIsLogin == true) {
        return (
            <Redirect to="/" />
        )
    }
        return (
          <div className="container h-100">


<div>
  <div className="row h-100 justify-content-center align-items-center">


  <Form  className="forgot-password-form col-5" onSubmit={this.handleSubmit}>
  <h3>{t('forgot.forgot_password')}</h3>

  <Form.Group className="form-group">
    <Form.Label>{t('forgot.email')}</Form.Label>
    <Form.Control required value={this.state.email} onChange={this.handleChange} name="email" id="email"  type="email" placeholder={t('forgot.enter_email')} />
  </Form.Group>

  <div className="panel panel-default">
 <FormErrors formErrors={this.state.formErrors} />
</div>

  <button type="submit"  className="btn btn-viposter btn-block">{t('forgot.send')}</button>

   <div className="message--alert">
           { this.props.propFormRegisterError ? this.showErrors(this.props.propFormRegisterError) : '' }  
   </div>

  </Form>
  </div>
</div>
                <SweetAlert 
                    success 
                    title="OK" 
                    show={this.state.sweetalert_success.show}
                    onConfirm={()=>{this.setState({sweetalert_success: {show: false}})}}
                >
                    {this.state.sweetalert_success.message}
                </SweetAlert>

                <SweetAlert 
                    warning 
                    title="Error" 
                    show={this.state.sweetalert_error.show}
                    onConfirm={()=>{this.setState({sweetalert_error: {show: false}})}}
                >
                     {this.state.sweetalert_error.message}
                </SweetAlert>
        </div>
        );
    }
 }


 const mapStateToProps = state => {
  return {
    propIsLogin: state.isLogin,
    propUser: state.user,
    propFormRegisterError: state.formRegisterError
  }
}

const mapDispatchToProps = dispatch => {
  return {
    registerUser: (registerData) => dispatch(actionCreators.registerUser(registerData)),
  }
}

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(ForgotPassword);