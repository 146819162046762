import React, {useLayoutEffect} from 'react';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
import blankTemplate from './../../assets/img/template_zuzel.png';
import './TemplateEditor.css';

class TemplateEditor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      posterTemplate: {},
      dimension: null
    }
  }


  myTheme = {
    // Theme object to extends default dark theme.
  };

  editorRef = React.createRef();

  


  componentDidMount() {

    // window.addEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {

    const editorInstance = this.editorRef.current.getInstance();
    var canvasSize = editorInstance.getCanvasSize();
    console.log('canvas width', canvasSize.width);
    console.log('canvas height', canvasSize.height);
    this.setState({dimension: {width: window.innerWidth, height: window.innerHeight }});
    // console.log('new dimension:', this.state.dimension);


    
    if(window.innerWidth<630){
        
      const canvasWidth = window.innerWidth-50;
      const canvasHeight = window.innerHeight-50;

      const uiWidth = window.innerWidth-50;
      const uiHeight = window.innerHeight-50;

      editorInstance.resizeCanvasDimension({width: canvasWidth, height: canvasHeight});

      editorInstance.ui.resizeEditor({
        imageSize: {newWidth: canvasWidth, newHeight: canvasHeight},
        uiSize: {width: uiWidth+'px', height: uiHeight+'px'}

    });

    }



  };



  componentDidUpdate(prevProps) {
    console.log('orev props', prevProps);
    //if template is loaded first time
    if(!prevProps.loadedPosterTemplate && this.props.loadedPosterTemplate.posterTemplate){
      this.loadImageFromURL(this.props.loadedPosterTemplate.posterTemplate);
    }
    else if(this.props.loadedPosterTemplate.controlClick != prevProps.loadedPosterTemplate.controlClick){
      this.loadImageFromURL(this.props.loadedPosterTemplate.posterTemplate);
    }
  
    //save image to the server - as poster or template
    if(this.props.clickSave.number != prevProps.clickSave.number ){
      // const imageType = clickSave.imageType;

      this.saveImage(this.props.clickSave.type);
    }



  }

  loadImageFromURL = (posterTemplate) => {

    // if(posterTemplate.id != this.state.posterTemplate.id){
      if(posterTemplate.id){

      this.setState({posterTemplate: posterTemplate});
      const editorInstance = this.editorRef.current.getInstance();

      editorInstance.clearObjects().then(() => {
         editorInstance.loadImageFromURL(posterTemplate.url, 'name.png').then(result => {
        });
    });
    }
  }

  


  saveImage = (imageType) => {
    const editorInstance = this.editorRef.current.getInstance();
      const myImage = editorInstance.toDataURL({
        format: 'png'
      });  
/*jpg
      const picture = {
        image: myImage,
        name: 'image',
        size: myImage.length,
        type: 'img/png'
      }
*/
      this.props.saveImage(myImage, imageType);


  }


  render() {


      const { t } = this.props;

    return (
        <ImageEditor

          ref={this.editorRef}
          includeUI={{
            loadImage: {
              path: blankTemplate,
              name: 'Image'
            },
            theme: this.myTheme,
            menu: ['draw','text', 'crop'],
            initMenu: 'text',
            uiSize: {
            width: '600px',
            height: '1000px'
          },
          menuBarPosition: 'bottom'
        }}
          cssMaxHeight={700}
          cssMaxWidth={500}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70
          }}
          usageStatistics={false}
      />
    );
  }
}

export default TemplateEditor;