import * as React from 'react';
import './BarChart.css';
import Paper from '@material-ui/core/Paper';
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  SplineSeries,
  BarSeries,
  Title,
  Legend,} from '@devexpress/dx-react-chart-material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp as farThumbsUp } from '@fortawesome/free-solid-svg-icons';
import * as Icon from 'react-bootstrap-icons';
import { Stack, Animation } from '@devexpress/dx-react-chart';
import { withTranslation } from 'react-i18next';


class BarChart extends React.Component {


  specialMarkerComponent = ({ name, color }) => {

    if(name==='Likes'){
      return (<FontAwesomeIcon icon={farThumbsUp} prefix="far" color={color} size="1x"  />
      );
    }

    if(name==='Favorites'){
      return (<Icon.StarFill color={color} size={17} />);
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      likes: [],
      favorites: []
    }
  }


  render() {

    const { t } = this.props;


    return(
        <Paper className="statistics-chart">
        <Chart className="statistics-chart"  
          data={this.props.data}
        >
          <ArgumentAxis />
          <ValueAxis />
    
          <BarSeries name="Likes" valueField="likes" argumentField="date" color="#3366ff" />

          <BarSeries name="Favorites" valueField="favorites" argumentField="date"   color="#ffd700" />


          <Title
            text={this.props.title}
          />

          <Animation />
          <Legend
            markerComponent={this.specialMarkerComponent}
          />
         <Stack />
        </Chart>
      </Paper>
    );
  }
}


export default withTranslation('translations')(BarChart);

//https://devexpress.github.io/devextreme-reactive/react/chart/docs/guides/legend_title/