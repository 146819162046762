import axios from './../../axios';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const SHOW_EDIT_USER_MODAL = 'SHOW_EDIT_USER_MODAL';
export const ADD_FAVORITES_POSTERS = 'ADD_FAVORITES_POSTERS';
export const REMOVE_FAVORITES_POSTERS = 'REMOVE_FAVORITES_POSTERS';
export const ADD_LIKED_POSTERS = 'ADD_LIKED_POSTERS';
export const REMOVE_LIKED_POSTERS = 'REMOVE_LIKED_POSTERS';
export const ADD_SELECTED_FAVORITE_POSTER = 'ADD_SELECTED_FAVORITE_POSTER';
export const REMOVE_SELECTED_FAVORITE_POSTER = 'REMOVE_SELECTED_FAVORITE_POSTER';
export const SET_PAGE = 'SET_PAGE';
export const SET_ACTIVE_INDEX = 'SET_ACTIVE_INDEX';
export const ADD_SEARCHED_POSTERS = 'ADD_SEARCHED_POSTERS';
export const REPLACE_SEARCHED_POSTERS = 'REPLACE_SEARCHED_POSTERS';
export const SET_NEW_SEARCH_FLAG = 'SET_NEW_SEARCH_FLAG';

export const getRegisterUserError = (error) => {

    const parsedError = JSON.parse(error);

    const errors = parsedError.error;


    return {
        type: REGISTER_USER_ERROR,
        formError: errors
    }
}

export const getLoginUserError = (error) => {

    const parsedError = JSON.parse(error);
    let errorMessage = '';

    switch (parsedError.error) {
        case 'Unauthorised':
            errorMessage = 'Email or password is not correct.';
            break;

        default:
            errorMessage = 'Email or password is not correct.';
            break;
    }


    return {
        type: LOGIN_USER_ERROR,
        formError: errorMessage
    }
}

export const getEditUserError = (error) => {

    const parsedError = JSON.parse(error);

    const errors = parsedError.error;

    console.log('formUpdateUserError - actions');


    return {
        type: UPDATE_USER_ERROR,
        formError: errors
    }
}



export const registerUser = (registerData) => {
    return dispatch => {

        axios.post('/api/user/register', registerData)
            .then(response => {

                localStorage.setItem("access_token", response.data.success.token);
                localStorage.setItem("user_name", response.data.success.user.name);
                localStorage.setItem("user_email", response.data.success.user.email);

                // this.setState({isOpen: true});
                // this.clearFormErrors();
                const dataLogin = {
                    accessToken: response.data.success.token,
                    user: response.data.success.user
                }
       

                dispatch(loginUser(response.data.success.user, [], []));
                dispatch(searchPosters([], 1, false, true));


            }).catch((error) => {
                if (error.request.response) {
                    dispatch(getRegisterUserError(error.request.response))
                } else {
                    
                    dispatch(getRegisterUserError({
                        error: 'Something went wrong'
                    }))
                }

            });
    }
}


export const editUser = (userData) => {


    return dispatch => {

        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`
            }
        };

        console.log('before');
        console.log(userData);


        axios.post('/api/user/edit', userData, config)
            .then(response => {

                // localStorage.setItem("access_token", response.data.success.token);
                // localStorage.setItem("user_name", response.data.success.user.name);
                // localStorage.setItem("user_email", response.data.success.user.email);

                // this.setState({isOpen: true});
                // this.clearFormErrors();
                // const dataLogin = { accessToken: response.data.success.token, user: response.data.success.user }
                console.log('success');
                dispatch(updateUser(response.data.user));


            }).catch((error) => {
                console.log('fail');
                console.log(error);
                // console.log(error.request.response);
                if (error.request.response) {
                    dispatch(getEditUserError(error.request.response))
                } else {
                    dispatch(getEditUserError({
                        error: 'Something went wrong'
                    }))
                }

            });
    }
}


export const login = (dataLogin) => {

    return dispatch => {
        axios.post('/api/user/login', dataLogin)
            .then(response => {

                if (response.data.success.token) {
                    localStorage.setItem("access_token", response.data.success.token);
                    localStorage.setItem("user_name", response.data.success.user.name);
                    localStorage.setItem("user_email", response.data.success.user.email);

                    const favoritesPosters = response.data.success.posters.favoritesPosters;
                    const likedPosters = response.data.success.posters.likedPosters;
                    const usersPosters = response.data.success.posters.usersPosters;

                    localStorage.setItem("favoritesPosters", JSON.stringify(favoritesPosters));
                    localStorage.setItem("likedPosters", JSON.stringify(likedPosters));
                    localStorage.setItem("usersPosters", JSON.stringify(usersPosters));


                    dispatch(loginUser(response.data.success.user, favoritesPosters, likedPosters));
                    dispatch(searchPosters([], 1, false, true));
                } else {
                    const err = 'Access Token error';
                    dispatch(getLoginUserError(err))
                }
            }).catch((error) => {
                if (error.request.response) {
                    dispatch(getLoginUserError(error.request.response))
                } else {
                    dispatch(getLoginUserError({
                        error: 'Something went wrong'
                    }))
                }
            });
    };
}

export const facebookLogin = (dataLogin) => {


    return dispatch => {
        axios.post('https://viposters.lim.bz/api/user/facebook', dataLogin)
            .then(response => {
                localStorage.setItem("access_token", response.data.access_token);

                const favoritesPosters = response.data.posters.favoritesPosters;
                const likedPosters = response.data.posters.likedPosters;

                dispatch(loginUser(dataLogin.user, favoritesPosters, likedPosters));
                dispatch(searchPosters([], 1, false, true));

            });
    };
}


export const loginUser = (user, favoritesPosters, likedPosters) => {
    return {
        type: LOGIN,
        user: user,
        favoritesPosters: favoritesPosters,
        likedPosters: likedPosters
    }
}

export const logout = () => {
    return {
        type: LOGOUT
    }
}


export const updateUser = (user) => {
    return {
        type: UPDATE_USER,
        user: user
    }
}


export const showEditUserModal = (showModal) => {
    return {
        type: SHOW_EDIT_USER_MODAL,
        showModal: showModal
    }
}


export const manageFavoritesPosters = (dataPosters) => {

    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
    };

    return dispatch => {
        axios.post('/api/v1/favorite', dataPosters, config)
            .then(response => {

                // add or remove element from array
                if (dataPosters.is_favorite === true) {
                    dispatch(AddFavoritesPosters(dataPosters.poster_id));
                } else {
                    dispatch(RemoveFavoritesPosters(dataPosters.poster_id));
                }
            });
    }
}

export const manageLikedPosters = (dataPosters) => {

    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
    };

    return dispatch => {
        axios.post('/api/v1/like', dataPosters, config)
            .then(response => {

                // add or remove element from array
                if (dataPosters.is_liked === true) {
                    dispatch(AddLikedPosters(dataPosters.poster_id));
                } else {
                    dispatch(RemoveLikedPosters(dataPosters.poster_id));
                }
            });
    }
}



export const AddFavoritesPosters = (poster_id) => {
    return {
        type: ADD_FAVORITES_POSTERS,
        poster_id: poster_id,
    }
}

export const RemoveFavoritesPosters = (poster_id) => {
    return {
        type: REMOVE_FAVORITES_POSTERS,
        poster_id: poster_id,
    }
}

export const AddLikedPosters = (poster_id) => {
    return {
        type: ADD_LIKED_POSTERS,
        poster_id: poster_id,
    }
}

export const RemoveLikedPosters = (poster_id) => {
    return {
        type: REMOVE_LIKED_POSTERS,
        poster_id: poster_id,
    }
}

export const manageSelectedFavoritesPosters = (dataPosters) => {
    const type = dataPosters.is_selected === true ? ADD_SELECTED_FAVORITE_POSTER : REMOVE_SELECTED_FAVORITE_POSTER;
    return {
        type: type,
        poster_id: dataPosters.poster_id,
    }
}

export const setPage = (page) => {

    return {
        type: SET_PAGE,
        page: page,
    }
}

export const setActiveIndex = (index) => {
    return {
        type: SET_ACTIVE_INDEX,
        activeIndex: index,
    }
}

export const setNewSearchFlag = (newSearchFlag) => {
    return {
        type: SET_NEW_SEARCH_FLAG,
        newSearchFlag: newSearchFlag,
    }
}



export const searchPosters = (data, page, activeIndexFlag, newSearch) => {
console.log('nowy search posters: ', page);
    // console.log('actions searched posters ', activeIndexFlag);  test
    return dispatch => {
        axios.post(
            `/api/v1/search/${ page }`,
            {
                ...data,
                limit: 10,  //limit 120
            }
        )
        .then(response => {
            const posters = [];
        
            response.data.posters.map(obj=> {
                posters.push(obj);
            })

            let hasMore = response.data.hasMore;

            console.log('actions posters');
            console.log(posters);
            if(newSearch){
                console.log('new search', page);

                dispatch(replaceSearchedPosters(posters, hasMore));
                console.log('new search22: ', page);

                // dispatch(setActiveIndex(1));
                // dispatch(setNewSearchFlag(true));  //dispach new search flag? I'm not sure ????? maybe uncomment
                dispatch(setPage(page+1));
                console.log('new search33: ', page);

            } else {
                console.log('add search', page);
                console.log('add has more', hasMore);
                if(hasMore){
                    dispatch(setPage(page+1));
                    dispatch(addSearchedPosters(posters, hasMore));
                }
                
                // dispatch(setNewSearchFlag(false));
            }

            // switch(activeIndexFlag){
            //     case 'prev':
            //         dispatch(setActiveIndex(posters.length));
            //     break;
            //     case 'next':
            //         dispatch(setActiveIndex(0));
            //     break;
            // }

        });
    }
}

export const addSearchedPosters = (posters, hasMore) => {
    return {
        type: ADD_SEARCHED_POSTERS,
        posters: posters,
        hasMore: hasMore
    }
}


export const replaceSearchedPosters = (posters, hasMore) => {
    return {
        type: REPLACE_SEARCHED_POSTERS,
        posters: posters,
        hasMore: hasMore
    }
}
