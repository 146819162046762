import React from 'react';
import { slide as Menu } from 'react-burger-menu'
import "./MenuLoggedUser.css";
import { Link, withRouter} from 'react-router-dom';
import { withTranslation } from 'react-i18next';


class MenuLoggedUser extends React.Component {
  showSettings (event) {
    event.preventDefault();
  }

  handleLogout = () => {
    this.props.submitLogout();
  };

  // handlePosterPanel = () => {
  //   const accessToken = localStorage.getItem('access_token');
  //   const cookies = new Cookies();

  //   // cookies.set('viposterAccessToken', accessToken, { path: '/',  sameSite: true, secure: false });
  //   // window.location.assign('htts://');

  //   cookies.set('viposterAccessToken', accessToken, { path: '/', domain: '.lim.bz', sameSite: false, secure: false });
  //   window.location.assign('https://viposters.lim.bz/login-gate');
  // }


  render () {
    const { t } = this.props;

    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
        <Menu right pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" } >

        <Link to='/'>
          <a href="#" className="menu-item">{t('menu.home')}</a>
        </Link>

        {/* <a id="my-posters" className="menu-item" href="#" onClick={this.handlePosterPanel}>{t('menu.poster_panel')}</a> */}

        <Link to='/my-posters'>
          <a href="#" className="menu-item">{t('menu.my_posters')}</a>
        </Link>
        <Link to='/favorites'>
          <a href="#" className="menu-item">{t('menu.favorites')}</a>
        </Link>

        <Link to='/my-profile'>
          <a href="#" className="menu-item">{t('menu.my_profile')}</a>
        </Link>
        <a id="logout" className="menu-item" href="#" onClick={this.handleLogout}>{t('menu.logout')}</a>
      </Menu>
    );
  }
}

export default withTranslation('translations')(withRouter(MenuLoggedUser));
