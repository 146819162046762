import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import langPL from '../../../assets/img/header/language_PL.svg';
import langEN from '../../../assets/img/header/language_EN.svg';
import './ToggleLanguage.scss';

class ToggleLanguage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    }
  }

  toggleLanguage = () => {
    const { i18n } = this.props;
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    if (i18n.language.includes('pl')) {
      changeLanguage('en')
    } else {
      changeLanguage('pl')
    }
  }

  render() {
    const { i18n } = this.props;

    return (
      <div
        className="togglelanguage"
        onClick={ this.toggleLanguage }
      >
        {
          i18n.language.includes('pl')
            ?
              <img className="headericon" src={ langPL }/>
            :
              <img className="headericon" src={ langEN } />
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({ propIsLogin: state.isLogin })

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps)
)(ToggleLanguage);
