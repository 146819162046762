import React, { Component } from 'react';


class Geolocation extends Component {
    constructor() {
      super()
  
      this.state = {
        latitude: '',
        longitude: '',
      }
  
      this.getMyLocation = this.getMyLocation.bind(this)
    }
    
    componentDidMount() {
      this.getMyLocation()
    }
  
    getMyLocation() {
      const location = window.navigator && window.navigator.geolocation
      
      if (location) {
        location.getCurrentPosition((position) => {
          this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
          this.props.onSubmit(this.state);
        }, (error) => {
          this.setState({ latitude: '', longitude: '' })
          this.props.onSubmit(this.state);
        })
      }
  
    }
  
    render() {
      const { latitude, longitude } = this.state
      
      return ('')
    }
  }

  
  export default Geolocation;
