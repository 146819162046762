import i18next from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';


i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    fallbackLng: 'en',
    react: {
      wait: true
    },
    ns: ['translations'],
    defaultNS: 'translations',
    lng: 'en' | 'pl', // 'en' | 'es'
    // Using simple hardcoded resources for simple example
    resources: {
      en: {
        translations: {
          global: {
            success: 'SUCCESS',
            error: 'ERROR',
            unknown_error: 'UNKNOWN ERROR'
          },
          search_form: {
            name: 'Name',
            location: 'Location',
            categories: 'Categories',
            search: 'SEARCH',
            category: 'Category',
            type_category: 'Type category',
            type_location: "Location",
            date: 'Date',
            tag: 'Tag',
          },
          home: {
            recommended: 'Recommended',
            most_popular: 'Most popular',
            recently_added: 'Recently added'
          },
          header: {
            home: 'Home',
            contact: 'Contact',
            posters: 'Posters',
            about_us: 'About us'
          },
          login: {
            welcome: 'Welcome',
            username: 'Username',
            password: 'Password',
            login: 'Login',
            sign_up: 'Sign up',
            email_verified: 'The email has been verified successfully',
          },
          my_profile: {
            name: 'Name',
            email: 'Email address',
            current_password: 'Current password',
            new_password: 'New password',
            update: 'Update',
            edit_user: 'Edit user',
            account_updated: 'Your account has been updated!',
            enter_name: 'Enter name',
            enter_email: 'Enter email',
            enter_current_password: 'Enter current password',
            enter_new_password: 'Enter new password'
          },
          favorites: {
            your_favorite_posters: 'Your favorite posters',
            remove_selected: 'Remove selected'
          },
          register: {
            congratulations: 'Congratulations!',
            you_have_been_registered: 'You have been registered!',
            sign_up: 'Sign up',
            name: 'Name',
            email: 'Email',
            password: 'Password',
            confirm_password: 'Confirm password',
            enter_name: 'Enter name',
            enter_email: 'Enter email',
            enter_password: 'Enter password',
            enter_confirm_password: 'Enter password',
            forgot_your_password: 'Forget your password?',
            click_here: 'Click here',
            sign_up_button: 'Sign up'
          },
          menu: {
            home: 'Home',
            poster_panel: 'Poster panel',
            my_posters: 'My posters',
            favorites: 'Favorites',
            my_profile: 'My profile',
            logout: 'Logout',
            login: 'Login',
            register: 'Register'
          },
          footer: {
            all_rights_reserved: 'Viposter 2020 - all rights reserved',
            terms: 'TERMS',
            about_us: 'ABOUT US',
            contact_us: 'CONTACT US'
          },
          one_poster: {
            active: 'active',
            inactive: 'inactive',
            name: 'Name',
            date: 'Date',
            location: 'Location',
            likes: 'Likes',
            edit: 'edit',
            statistics: 'statistics',
            delete: 'delete'
          },
          my_posters: {
            add_poster: 'Add poster',
            my_posters: 'My posters'
          },
          forgot: {
            forgot_password: 'Forgot password',
            email: 'Email address',
            enter_email: 'Enter email address',
            send: 'Send'
          },
          password: {
            change_password: 'Change password',
            email: 'Adress email',
            password: 'Password',
            confirm_password: 'Confirm password',
            enter_name: 'Enter name',
            enter_email: 'Enter email',
            enter_password: 'Enter password',
            enter_confirm_password: 'Enter password',
          },
          add_poster: {
            add_your_new_poster: 'Add your new poster',
            name: 'Name',
            location: 'Location',
            redirect_url: 'Redirect URL',
            upload_image: 'Upload image',
            categories: 'Categories',
            tags: 'Tags',
            add_poster: 'Add poster',
            poster_has_been_added: 'Poster has been added',
            use_template: 'Use template',
            or: 'or'
          },
          edit_poster: {
            edit_your_poster: 'Edit your poster',
            name: 'Name',
            location: 'Location',
            redirect_url: 'Redirect URL',
            upload_image: 'Upload image',
            categories: 'Categories',
            tags: 'Tags',
            update: 'Update',
            poster_has_been_updated: 'The poster has been successfully updated',
          },
          templates: {
            save_as_poster: 'Save as poster',
            save_as_template: 'Save as template',
            load: 'Load',
            remove: 'Remove',
            templates: 'Templates'
          },
          statistics: {
            last_30_days: 'Last 30 days',
            monthly: 'Monthly',
            likes: 'Likes',
            favorites: 'Favorites'
          }
        },
      },
      pl: {
        translations: {
          global: {
            success: 'SUKCES',
            error: 'BŁĄD',
            unknown_error: 'Nieznany błąd'
          },
          search_form: {
            name: 'Nazwa',
            location: 'Lokalizacja',
            categories: 'Kategoria',
            search: 'SZUKAJ',
            type_category: 'Dodaj kategorię',
            category: 'Kategoria',
            type_location: 'Lokalizacja',
            date: 'Data',
            tag: 'Tag',
          },
          home: {
            recommended: 'Polecane',
            most_popular: 'Popularne',
            recently_added: 'Ostatnio dodane'
          },
          header: {
            home: 'Strona główna',
            contact: 'Kontakt',
            posters: 'Plakaty',
            about_us: 'O nas'
          },
          login: {
            welcome: 'Witaj',
            username: 'Nazwa użytkownika',
            password: 'Hasło',
            login: 'Zaloguj',
            sign_up: 'Zarejestruj',
            email_verified: 'The email has been verified successfully',
          },
          my_profile: {
            name: 'Nazwa użytkownika',
            email: 'Adres Email',
            current_password: 'Obecne hasło',
            new_password: 'Nowe hasło',
            update: 'Zapisz',
            edit_user: 'Edytuj dane',
            account_updated: 'Twoje konto zostało zaktualizowane!',
            enter_name: 'Wpisz nazwę użytkownika',
            enter_email: 'Wpisz adres email',
            enter_current_password: 'Wpisz obecne hasło',
            enter_new_password: 'Wpisz nowe hasło'
          },
          favorites: {
            your_favorite_posters: 'Polubione plakaty',
            remove_selected: 'Usuń zaznaczone'
          },
          register: {
            congratulations: 'Gratulacje!',
            you_have_been_registered: 'Twoje konto zostało żałożone!',
            sign_up: 'Rejestracja',
            name: 'Nazwa użytkownika',
            email: 'Adres email',
            password: 'Hasło',
            confirm_password: 'Potwierdź hasło',
            enter_name: 'Wpisz nazwę użytkownika',
            enter_email: 'Wpisz adres email',
            enter_password: 'Wpisz hasło',
            enter_confirm_password: 'Potwierdź hasło',
            forgot_your_password: 'Zapomniałeś hasła?',
            click_here: 'kliknij tutaj',
            sign_up_button: 'Zarejestruj'
          },
          menu: {
            home: 'Strona główna',
            poster_panel: 'Poster Panel',
            my_posters: 'Moje plakaty',
            favorites: 'Ulubione',
            my_profile: 'Moje konto',
            logout: 'Wyloguj',
            login: 'Zaloguj',
            register: 'Rejestracja'
          },
          footer: {
            all_rights_reserved: 'Viposter 2020 - wszystkie prawa zastrzeżone',
            terms: 'POLITYKA PRYWATNOŚCI',
            about_us: 'O NAS',
            contact_us: 'KONTAKT'
          },
          one_poster: {
            active: 'aktywny',
            inactive: 'nieaktywny',
            name: 'Nazwa',
            date: 'Data',
            location: 'Lokalizacja',
            likes: 'Polubienia',
            edit: 'edytuj',
            statistics: 'statystyki',
            delete: 'usuń'
          },
          my_posters: {
            add_poster: 'Dodaj plakat',
            my_posters: 'Moje plakaty'
          },
          forgot: {
            forgot_password: 'Przypomnienie hasła',
            email: 'Email',
            enter_email: 'Wpisz adres email',
            send: 'Wyślij'
          },
          password: {
            change_password: 'Zmień hasło',
            email: 'Adres email',
            password: 'Hasło',
            confirm_password: 'Potwierdź hasło',
            enter_name: 'Wpisz nazwę użytkownika',
            enter_email: 'Wpisz adres email',
            enter_password: 'Wpisz hasło',
            enter_confirm_password: 'Potwierdź hasło'
          },
          add_poster: {
            add_your_new_poster: 'Dodaj nowy plakat',
            name: 'Nazwa',
            location: 'Lokalizacja',
            redirect_url: 'Przekierowanie',
            upload_image: 'Załaduj zdjęcie',
            categories: 'Kategorie',
            tags: 'Tagi',
            add_poster: 'Dodaj',
            poster_has_been_added: 'Plakat został dodany',
            use_template: 'Użyj szablonu',
            or: 'lub'
          },
          edit_poster: {
            edit_your_poster: 'Zmień ustawienia plakatu',
            name: 'Nazwa',
            location: 'Lokalizacja',
            redirect_url: 'Przekierowanie',
            upload_image: 'Załaduj zdjęcie',
            categories: 'Kategorie',
            tags: 'Tagi',
            update: 'Zaktualizuj',
            poster_has_been_updated: 'Zmiany zostały wprowadzone',
          },
          templates: {
            save_as_poster: 'Zapisz jako plakat',
            save_as_template: 'Zapisz jako szablon',
            load: 'Załaduj',
            remove: 'Usuń',
            templates: 'Szablony'
          },
          statistics: {
            last_30_days: 'Ostatnie 30 dni',
            monthly: 'Miesięcznie',
            likes: 'Polubienia',
            favorites: 'Ulubione'
          }
        },
      },
    },
  })

export default i18next
