import React from "react";
import { Form, Button, Modal } from "react-bootstrap";
import axios from "./../../axios";
import FormErrors from "./../../components/FormErrors.js";
import "./LoginForm.css";
import { Route, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/actions";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import FacebookLoginWithButton from "./../Forms/FacebookLoginWithButton";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      password: null,
      validated: false,
      success: "",
      message: "",
      formErrors: { email: "", password: "" },
      emailValid: false,
      passwordValid: false,
      confirmPasswordValid: false,
      formValid: false,
      passwordError: "",
      isOpen: false,
      isLogin: false,
      formLoginError: null,
      showVerified: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  clearFormErrors() {
    this.setState({
      formErrors: { email: "", password: "" },
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const dataLogin = {
      email: this.state.email,
      password: this.state.password,
    };

    this.props.userLogin(dataLogin);
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  hideModal() {
    this.setState({ isOpen: false });
  }


  checkRequired(value) {
    return !!value.trim();
  }

  render() {
    const { t } = this.props;

    const isLogged = !!localStorage.getItem("access_token");

    if (isLogged) {
      return <Redirect to="/" />;
    }

    return (
      <div className="container h-100">
        <div className="row h-100 justify-content-center align-items-center">
          <Form className="register-form col-5" onSubmit={this.handleSubmit}>
            <h3>{t("login.welcome")}</h3>

            <Form.Group className="form-group" controlId="formBasicEmail">
              <Form.Label>{t("register.email")}</Form.Label>
              <Form.Control
                required
                value={this.state.email}
                onChange={this.handleChange}
                name="email"
                id="email"
                type="email"
                placeholder={t("register.enter_email")}
              />
            </Form.Group>

            <Form.Group className="form-group" controlId="formBasicPassword">
              <Form.Label>{t("register.password")}</Form.Label>
              <Form.Control
                required
                value={this.state.password}
                onChange={this.handleChange}
                validate={this.checkRequired}
                type="password"
                name="password"
                id="password"
                placeholder={t("register.enter_password")}
              />
            </Form.Group>

            <div className="panel panel-default">
              <FormErrors formErrors={this.state.formErrors} />
            </div>

            <button type="submit" className="btn btn-viposter btn-block">
              {t("login.login")}
            </button>

            <FacebookLoginWithButton />

            <p className="forgot-password text-right">
              {t("register.forgot_your_password")}
              <Link to={{ pathname: "/forgot" }}>
                <a href="#">{t("register.click_here")}</a>
              </Link>
            </p>

            <div className="message--alert">
              {this.props.propFormLoginError
                ? this.props.propFormLoginError
                : ""}
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propIsLogin: state.isLogin,
    propUser: state.user,
    propFormLoginError: state.formLoginError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogin: (dataLogin) => dispatch(actionCreators.login(dataLogin)),
    userLogout: () => dispatch(actionCreators.logout()),
  };
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps)
)(LoginForm);
