import React, { Component } from 'react';
import {FacebookShareButton, FacebookIcon} from "react-share";

class SocialMediaButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poster: this.props.poster,
      quote: "Udostępnione przez Viposter: "+this.props.poster.name,
      hashtag: "#viposter"
    };
  }

 

  
  render() {
    console.log("poster", this.state.poster);
    return(
      <FacebookShareButton 
        url={this.state.poster.redirectURL ?? "http://vipsoter.lim.bz"}
        quote={this.state.quote}
        hashtag={this.state.hashtag}
        className="klasa">
       <FacebookIcon size={36} />
      </FacebookShareButton>
    )
  } 
}

export default SocialMediaButtons;