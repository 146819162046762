import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import dots from './../../../../assets/img/icons/category-dots.svg';
import './CategoryModal.scss';


class CategoryModal extends Component {

  constructor(props) {
    super(props);
    this.state =  {
      show: false,
      showMenu: 0
    }

    this.handleHover = this.handleHover.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
    this.handleCategory = this.handleCategory.bind(this);
  }

  importImages(r) {
    const parseUrl = (url) => url.replace('/static/media/', '').split('.')[0];
    return r.keys().map(r).reduce((obj, item) => {
      return {
        ...obj,
        [parseUrl(item)]: item,
      };
    }, {});
  }

  handleHover = (categoryId) => {
    this.setState({ showMenu: categoryId });
  };

  handleLeave = (categoryId) => {
    this.setState({ showMenu: 0 });
  };

  handleCategory = (categoryId) => {
    this.props.onHandleCategory(categoryId);
  }

  categoriesBlocks = () => this.props.categories.map(category =>
    {
      const images = this.importImages(
        require.context('./../../../../assets/img/categories', false, /\.(png|jpe?g|svg)$/)
      );

      const { t, i18n } = this.props;


      const subcategories = category.children.map(subcategory => {
        return (
          <div
            className="subcategory__item"
            key={ subcategory.id }
            onClick={ () => this.handleCategory(subcategory) }>
            { subcategory[i18n.language] }
          </div>
        )
      });

      return (
        <div
          onMouseEnter={ () => this.handleHover(category.id) }
          onMouseLeave={ () => this.handleLeave(category.id) }
          key={ category.id }
          className="categorymodal__tile"
        >
          <div className="categoryimage__container">
            <img
              className="categoryimage"
              alt="Category Image"
              src={images[category.slug]}
            />
          </div>
          <div
            className="categoryimage__label"
          >
            { category[i18n.language] }
            <img
              alt=""
              className="categoryimage__dots" src={dots} />
            { this.state.showMenu === category.id
              ?
              <div className="subcategory">
                  {subcategories}
                </div>
              : ""
            }
          </div>
        </div>
      )
    }
  );

  render() {
    return (
      this.props.show ?
      <div className={"categorymodal " + this.props.styleClass}>
          { this.categoriesBlocks() }
        </div>
      : ''
    )
  }
}

export default withTranslation('translations')(CategoryModal);
