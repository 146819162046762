import React from "react";
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import {Route,Link, Redirect, withRouter} from 'react-router-dom';
import OnePoster from "./OnePoster";
import { compose } from 'redux';
import axios from './../../axios';
import { withTranslation } from 'react-i18next';
import "./MyPosters.css";


//get users posters

//save in redux

class MyPosters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        posters: []
    };
  }

  //update my posters after delete from OnePoster
  handleRemovePoster = (posterId) => {
    const filtered = [...this.state.posters];
    const filteredPosters = filtered.filter(function(value, index, arr){ console.log('value', value.id); return value.id != posterId;});
    this.setState({posters: filteredPosters});
  }
 

  getUsersPosters = () => {
   
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
    };
    axios.get('/api/user/posters', config)
    .then(response => {
      const posters = [];
      response.data.map(obj=> {
        posters.push(obj);
      })
      console.log(posters);
      this.setState({ posters: posters});
    });
  }

  componentDidMount() {
    this.getUsersPosters();
  }

  removeSelected() {
    let dataPosters = [];
    this.props.removeSelectedPosters(dataPosters);
  }


  render() {


    const { t, i18n } = this.props;


    return (

        <div className="posters-container container-lg container">
        <div className="my-posters-header">
          <div className="h2-title">
            <h2>{t('my_posters.my_posters')}</h2>
          </div>
          <div className="add-poster">
          <Link to='/add-poster'>
              <a href="#" className="menu-item"><button className="btn btn-viposter">{t('my_posters.add_poster')}</button></a>
          </Link>
          </div>
        </div>
        
        <div className="posters-blocks">
        {this.state.posters.map((poster, index) => {
          return (
            
              <OnePoster key={poster.id}
                click={() => this.props.clicked(index)}
                poster={poster}
                changed={event => this.props.changed(event, poster.id)}
                handleRemovePoster={this.handleRemovePoster}
              />
            
          );
        })}
        </div>

        </div>

    );
  }
}




export default withTranslation('translations')(withRouter(MyPosters));