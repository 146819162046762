import React from 'react';
import axios from './../../axios';
import './AllTemplates.css';
import { withTranslation } from 'react-i18next';

class AllTemplates extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      posterTemplates: [],
      controlClick: 0
    }

    this.loadImage = this.loadImage.bind(this);
    this.removeImage = this.removeImage.bind(this);

  }

  removeImage(poster) {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
    };

    axios.delete('/api/v1/poster-template/'+poster.id, config)
    .then(response => {
      

      const filtered = [...this.state.posterTemplates];
      // const value = poster.id;

      const filteredTemplates = filtered.filter(function(value, index, arr){ return value.id != poster.id;});
      this.setState({posterTemplates: filteredTemplates});
      console.log('after:', this.state.posterTemplates);

    }).catch((error) => {


    });
  }

  loadImage(posterTemplate) {
    const data = {posterTemplate: posterTemplate, controlClick: this.state.controlClick}
    this.setState({controlClick: this.state.controlClick+1})
    this.props.loadPosterTemplate(data);
  }

  getPosterTemplates() {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
    };

    axios.get('/api/v1/poster-template', config)
    .then(response => {
      this.setState({posterTemplates: response.data});
     
      console.log('after after', this.state);

    }).catch((error) => {


    });
  }

  componentDidUpdate(prevProps){
    if(prevProps.newTemplate != this.props.newTemplate){
      this.getPosterTemplates();
    }
  }


  componentDidMount() {
    this.getPosterTemplates();
  }
//get all templates by axios
//send to PosterTemplate if clicked - then load
//prepare API on backed for templates of user


  render() {

  const { t } = this.props;


    return (
        <div>
          <h1>{t('templates.templates')}</h1>
          <div className="poster-template-blocks">
            {this.state.posterTemplates.map((posterTemplate, index) => {
              return (
                <div className="poster-template" key={posterTemplate.id}>
                  <button className="btn btn-statistics poster-button" onClick={() => this.loadImage(posterTemplate)}>
                  {t('templates.load')}
                  </button>
                  <button className="btn btn-statistics poster-button" onClick={() => this.removeImage(posterTemplate)}>
                  {t('templates.remove')}
                  </button>
                  <img className="template" src={posterTemplate.url} />
                </div>
          );
          })}
        </div>
        </div>
    );
  }
}

export default withTranslation('translations')(AllTemplates);
