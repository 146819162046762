import React, { useEffect } from 'react'
import { IndexKind } from 'typescript';
import './Posters.scss'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Posters = (props) => {
  const posters = props.posters;
  const onPosterClick = props.onPosterClick;

  const children = (posters) => {
    const totalPosters = posters.length;
    const maxLargePosters = 6;
    let largeCount = 1;

    const generatePosterClass = (index) => {
      return 0;
      if (largeCount >= maxLargePosters) { return '1' }
      largeCount++;
      return Math.floor(totalPosters / index) % 3 < 3 ? '2' : '1';
    }

    function generateImageStyle(index) {
      return {
        paddingTop: 0, //Math.floor(totalPosters / index) % 3 < 4 ? '5' : '3' + '%',
        paddingLeft: 0, //Math.floor(totalPosters / index) % 3 < 5 ? '3' : '5' + '%',
      }
    }

    return posters.map((poster, index) =>
      <div
        className={
          `gallery__item gallery__item--${index + 1}
          ${poster.position}
          ${poster.position}-${generatePosterClass(index)}`
        }
        key={ poster.id }
        style={ generateImageStyle(IndexKind) }
      >
        <img
          onClick={ () => onPosterClick(poster) }
          className="gallery__img"
          src={ poster.thumbnail }
          alt='' />
      </div>
    );
  }

  if (!posters || !posters.length) {
    return (
      <div>No Posters</div>
    )
  } else {
    return (
      <div className={ `gallery` }>
              {/* <Carousel
        axis='vertical'
        centerMode={true}
        verticalSwipe='standard'
        autoPlay={true}
      > */}
        { children(posters) }

      {/* </Carousel> */}
        
      </div>
    )
  }
}


export default Posters;
