import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './Categories.css';
import { withTranslation, Trans } from 'react-i18next';




  class CustomizedHook extends Component {
    constructor(props) {
      super(props);
      this.state = {
      }
    }

    handleChangeAutocomplete = (event, value)  => {
      this.props.handleChangeAutocomplete(event, value);
    };

    componentDidUpdate(prevProps, prevState, snapshot){
      console.log('default value', this.props.defaultValue);
    }

    render() {
      const { t } = this.props;


  return (
    <Autocomplete
      multiple
      autoComplete
      autoHighlight
      value={this.props.value}
      id="grouped-categories"
      options={this.props.categories}
      groupBy={(option) => option.parentCategory[this.props.language] || 'en' }
      getOptionLabel={(option) => option[this.props.language] || 'en'}
  style={{ width: 300 }}
  renderInput={(params) => <TextField className={"text-field"}  {...params} variant="outlined" placeholder={t('search_form.categories')}

  />}
  onChange={this.handleChangeAutocomplete}
  fullWidth={false}
  size="small"
    classes= {{
    root: 'autocomplete-root',
    listbox: 'autocomplete-popper',
  }}

/>
  
  );
}
  }

export default withTranslation('translations')(CustomizedHook);
