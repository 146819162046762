import React, {Component, Fragment} from 'react';
import './Footer.scss';
import { withTranslation } from 'react-i18next';
import logo from './../../assets/img/viposterLogo_darkBG_horizontal.svg';
import icon_facebook from './../../assets/img/icons/facebook.svg';
import icon_instagram from './../../assets/img/icons/instagram.svg';
import icon_twitter from './../../assets/img/icons/twitter.svg';
import icon_home from './../../assets/img/icons/home.svg';
import icon_profile from './../../assets/img/icons/profile.svg';
import AddPoster from './../Header/HeaderButtons/AddPoster';

class Footer extends Component {

  render() {
    const { t } = this.props;

    return (
      <Fragment >
        <footer className="footer show-desktop">
          <div className="footer__color footer__logo">
            <img
              src={ logo }
              alt="Viposter"
            />
          </div>
          <div className="footer__color">
            {t('footer.all_rights_reserved')}
          </div>
          <div className="footer__color footer__links">
            <span>{t('footer.terms')}</span>
            <span>{t('footer.about_us')}</span>
            <span>{t('footer.contact_us')}</span>
          </div>
          <div className="footer__color footer__links">
            <span>
              <img
                src={ icon_facebook }
                alt="Viposter on Facebook"
              />
            </span>
            <span>
              <img
                src={ icon_instagram }
                alt="Viposter on Instagram"
              />
            </span>
            <span>
              <img
                src={ icon_twitter }
                alt="Viposter on Twitter"
              />
            </span>
          </div>
        </footer>

        <footer className="footer footer__mobile show-mobile">
          <div className="footer__color">
            <img
              src={icon_home}
              alt="Home"
            />
          </div>
          <div className="footer__color">
            <img
              src={icon_profile}
              alt="profile"
            />
          </div>
          <div className="footer__color"></div>
          <div className="footer__color footer__addposter">
            <AddPoster />
          </div>
        </footer>
      </Fragment>
    )
  }
}

export default withTranslation('translations')(Footer);
