import React, { Component } from 'react';

// https://www.digitalocean.com/community/tutorials/how-to-build-an-infinite-scroll-image-gallery-with-react-css-grid-and-unsplash

import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/actions";
import { compose } from "redux";
import ModalPoster from "./../Mosaic/ModalPoster";
import "./LazyLoadingGallery.scss";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import InfiniteScroll from 'react-infinite-scroller';
import UnsplashImage from './UnsplashImage';

import { LazyLoadImage } from "react-lazy-load-image-component";
import loader_gif from '../../assets/img/icons/loader.gif';



class LazyLoadingGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: this.props.activeIndex,
      searchedPosters: this.props.searchedPosters ?? [],
      page: this.props.page,
      // newSearchFlag: this.props.newSearchFlag,
      isOpen: false,
      displayedImage: {},
      hasMoreImages: false,
      posterIndex: null,
      // activeSlideKey: 0
      //  newSearchFlag: this.props.newSearchFlag
    };

    this.onePosterClick = this.onePosterClick.bind(this);
    this.onSubmitLikes = this.onSubmitLikes.bind(this);
  }

  // const [images, setImages] = React.useState([]);
  // const [loaded, setIsLoaded] = React.useState(false);


  swipeRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return props;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidMount() {
    console.log("kmpo mounted");
    console.log(this.searchedPosters);
  }

  componentDidUpdate(prevProps) {
    // if(this.props.newSearchFlag == true) {
    //   const swiper = this.swipeRef.current.swiper;
    //   console.log("swiper", swiper);
    //   //  try to rebuild the swiper
    //   //  this.setState({activeSlideKey: 0});
    //   //  this.props.setNewSearchFlag(false);
    // }
  }

  onePosterClick(poster, poster_index) {
    console.log("poster clicked");
    console.log(poster_index);

    this.setState({ displayedImage: poster, isOpen: true, posterIndex: poster_index });
  }

  //immidiately show correct number of likes
  onSubmitLikes(data) {
    console.log('jakas data');
    console.log(data);
    this.state.searchedPosters[data.posterIndex].likes = data.likes;
    
    // this.state.searchedPosters.map((postersGroup, postersGroupIndex) => {
    //   console.log(postersGroup);
      // postersGroup.map((onePoster, onePosterIndex) => {
      //   if (onePoster.id == data.posterId) {
      //     this.state.searchedPosters[postersGroupIndex][onePosterIndex].likes =
      //       data.likes;
      //   }
      // });
    // });
  }

  // loadMore = () => {
  //   console.log("load more" + this.props.page);
  //   let nextPage = this.props.page + 1;
  //   this.props.setPage(nextPage);
  //   // if (page >= 5) {
  //   //   this.setState({ hasMoreImages: false });
  //   // }
  // };

  fetchImages = () => {
    console.log('fetch images');
    const dataSearch = {
      dateStart: null,
      dateEnd: null,
      tags: [],
      city: null,
      latitude: null,
      longitude: null,
      sort: "distance"
    };

    let nextPage = 1;
    console.log('set pagge');
    console.log(this.props.page);
    
    if(this.props.page){
      nextPage = this.props.page + 1;
     
    } else {
      nextPage = 1;
    }
    console.log('next page', nextPage);
    console.log('this props page', this.props.page);
    //get new posters
    
    
    this.props.searchPosters(dataSearch, nextPage, false, false);

  }

  render() {
    // const SwipePostersDiv = this.state.searchedPosters.map((poster, index) => {
    //   console.log("resulttt");
    //   console.log(image);
    //   return (
    //     <div>
    //       <LazyLoadImage
    //         onPosterClick={this.onePosterClick}
    //         alt={image.name}
    //         height="200"
    //         src={image.image} // use normal <img> attributes as props
    //         width="200"
    //       />
    //       <span>{image.name}</span>
    //     </div>

    //     //   <div
    //     //     className="swipe-posters"
    //     //     key={ index }
    //     //   >
    //     //     <Posters
    //     //       posters={ results }
    //     //       onPosterClick={ this.onePosterClick }
    //     //     />
    //     //   </div>
    //   );
    // });

    return (
      <div>
        <ModalPoster
          isOpen={this.state.isOpen}
          displayedImage={this.state.displayedImage}
          propIsLogin={this.props.propIsLogin}
          onSubmitLikes={this.onSubmitLikes}
          posterIndex={this.state.posterIndex}
        />
        {/* <div className="gallery">{SwipePostersDiv}</div> */}

        <div className="hero is-fullheight is-bold is-info">
          <div className="hero-body">
            <div className="container-images">
              <InfiniteScroll
                dataLength={this.props.searchedPosters}
                // next={() => this.fetchImages()}
                loadMore={() => this.fetchImages()}
                hasMore={this.props.hasMore}
                style={{ display: 'flex', flexDirection: 'column' }}
                loader={
                  <img
                    style={{width:50}}
                    src={loader_gif}
                    alt="loading"
                  />
                }
              >
                <div className="image-grid" style={{ marginTop: "30px" }}>
                  {this.state.searchedPosters.map((poster, index) => (
                        <UnsplashImage onePosterClick={ this.onePosterClick } poster={poster} key={index} poster_index={index} />
                      ))}
                  {/* {this.props.loaded
                    ? this.state.searchedPosters.map((poster, index) => (
                        <UnsplashImage poster={poster} key={index} />
                      ))
                    : ""} */}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </div>

        {/* react carousel: 
      https://www.npmjs.com/package/react-responsive-carousel */}
        {/* <Carousel
        axis='vertical'
        centerMode={true}
        verticalSwipe='standard'
        autoPlay={true}
      >
        {SwipePostersD/v}

      </Carousel> */}
        {/* <div style={{height:"700px",overflow:"auto"}}>
      <InfiniteScroll
          pageStart={0}
          loadMore={this.loadMore}
          hasMore={this.hasMoreImages}
          loader={<div className="loader" key={0}>Loading ...</div>}
          threshold={250}
          useWindow={false}
          
      >
          {SwipePostersDiv}
      </InfiniteScroll>
    </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    page: state.page,
    activeIndex: state.activeIndex,
    searchedPosters: state.searchedPosters ?? [],
    newSearchFlag: state.newSearchFlag,
    propIsLogin: state.isLogin,
    hasMore: state.hasMore
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPage: (page) => dispatch(actionCreators.setPage(page)),
    setActiveIndex: (index) => dispatch(actionCreators.setActiveIndex(index)),
    setNewSearchFlag: (flag) => dispatch(actionCreators.setNewSearchFlag(flag)),
    searchPosters: (data, page, activeIndexFlag, newSearch) =>
    dispatch(
      actionCreators.searchPosters(data, page, activeIndexFlag, newSearch)
    ),
  };
};



export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps)
)(LazyLoadingGallery);
