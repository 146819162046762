import React, { Component } from 'react';

class UnsplashImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  onePosterClick = (poster, poster_index) => {
    this.props.onePosterClick(poster, poster_index);
  }

  render() {
    return (
      <div className="image-item" key={this.props.key} >
        <img onClick={ () => this.onePosterClick(this.props.poster, this.props.poster_index) } src={this.props.poster.image} />
      </div>    
    )
  }

}



export default UnsplashImage;



// const UnsplashImage = ({ poster, key }) => (
//   <div className="image-item" key={key} >
//     <img src={poster.image} />
//   </div>
// );