import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';

import reducer from './store/reducer';

import {I18nextProvider} from 'react-i18next';
import i18n from './containers/i18n';


const logger = store => {
    return next => {
        return action => {
            console.log('[Middleware] Dispatching', action);
            const result = next(action);
            console.log('[Middleware] next state', store.getState());
            
            
            return result;
        }
    }
}




// const composeEnhancers =  window.__REDUX_DEVTOOLS_EXTENSION__ || compose;


const store = createStore(reducer, applyMiddleware(logger, thunk));



ReactDOM.render(
    
    <Provider store={store}>
        <I18nextProvider i18n={ i18n }>
        <App />
        </I18nextProvider>
    </Provider>
    , 
    document.getElementById('root'));
registerServiceWorker();


