import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Login from './../../Forms/Login';
import avatarActive from '../../../assets/img/header/avatar_active.svg';
import avatarInactive from '../../../assets/img/header/avatar_inactive.svg';
import './UserMenu.scss';

class UserMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    }
  }

  toggleMenu = () => this.setState({ show: !this.state.show })

  render() {
    return (
      <div className="usermenu__container">
        <div
          className="usermenu"
          onClick={ () => this.toggleMenu() }
        >
          {
            this.props.propIsLogin
              ?
                <img className="headericon" src={ avatarActive } />
              :
                <img className="headericon" src={ avatarInactive } />
          }
        </div>
        {
          this.state.show
            ?
              <div className="usermenu__menu">
                <Login />
              </div>
            : ''
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({ propIsLogin: state.isLogin })

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps)
)(UserMenu);
