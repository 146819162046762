const initialState = {
    isLogin: localStorage.getItem('access_token') ? true : false,
    formRegisterError: null,
    formLoginError: null,
    formUpdateUserError: null,
    formUpdateUserSuccess: false,
    user: {
        name:  localStorage.getItem('user_name')  ?  localStorage.getItem('user_name')   : '',
        email: localStorage.getItem('user_email') ?  localStorage.getItem('user_email')  : ''
    },
    favoritesPosters: localStorage.getItem('favoritesPosters')  ?  JSON.parse(localStorage.getItem('favoritesPosters'))   : [],
    likedPosters: localStorage.getItem('likedPosters')  ?  JSON.parse(localStorage.getItem('likedPosters'))   : [],
    userPosters: localStorage.getItem('usersPosters')  ?  JSON.parse(localStorage.getItem('usersPosters'))   : [],
    selectedFavoritesPosters: [],
    page: 1,
    activeIndex: 0,
    searchedPosters: [],
    newSearchFlag: false,
    hasMore: false
}

const reducer = (state = initialState, action) => {
    if (action.type === 'LOGIN') {
        return {
            isLogin: true,
            user: action.user,
            favoritesPosters: action.favoritesPosters,
            likedPosters: action.likedPosters,
            usersPosters: action.usersPosters
        }
    }

    if (action.type === 'LOGOUT') {
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_email');
        localStorage.removeItem('access_token');
        localStorage.removeItem('favoritesPosters');
        localStorage.removeItem('likedPosters');
        localStorage.removeItem('usersPosters');

        return {
            isLogin: false,
            user: null,
            formLoginError: null,
            favoritesPosters: null,
            likedPosters: null,
            usersPosters: null
        }
    }

    if (action.type === 'LOGIN_USER_ERROR') {
        return {
            isLogin: false,
            formLoginError: action.formError
        }
    }


    if (action.type === 'REGISTER_USER_ERROR') {
        return {
            isLogin: false,
            formRegisterError: action.formError
        }
    }


    if (action.type === 'UPDATE_USER') {

        return {
            ...state,
            user: action.user,
            formUpdateUserSuccess: true,
            formUpdateUserError: null
        }
    }

    if (action.type === 'UPDATE_USER_ERROR') {
        return {
            ...state,
            formUpdateUserError: action.formError,
            formUpdateUserSuccess: false
        }
    }

    if (action.type === 'SHOW_EDIT_USER_MODAL') {

        return {
            ...state,
            formUpdateUserSuccess: action.showModal,
            formUpdateUserError: null
        }
    }


    if (action.type === 'ADD_FAVORITES_POSTERS') {

        const filteredPosters = [...state.favoritesPosters, action.poster_id];
        localStorage.setItem("favoritesPosters", JSON.stringify(filteredPosters));

        return {
            ...state,
            favoritesPosters: filteredPosters
        }
    }

    if (action.type === 'REMOVE_FAVORITES_POSTERS') {

        const filtered = [...state.favoritesPosters];
        const filteredPosters = filtered.filter(function(value, index, arr) { return value !== action.poster_id;});
        localStorage.setItem("favoritesPosters", JSON.stringify(filteredPosters));


        return {
            ...state,
            favoritesPosters: filteredPosters,
            selectedFavoritesPosters: []
        }
    }

    if (action.type === 'ADD_LIKED_POSTERS') {

        const filteredPosters = [...state.likedPosters, action.poster_id]
        localStorage.setItem("likedPosters", JSON.stringify(filteredPosters));

        return {
            ...state,
            likedPosters: filteredPosters
        }
    }

    if (action.type === 'REMOVE_LIKED_POSTERS') {
        const filtered = [...state.likedPosters];
        const filteredPosters = filtered.filter(function(value, index, arr) { return value !== action.poster_id;});

        localStorage.setItem("likedPosters", JSON.stringify(filteredPosters));

        return {
            ...state,
            likedPosters: filteredPosters
        }
    }

    if (action.type === 'ADD_SELECTED_FAVORITE_POSTER') {
        const filteredPosters = [...state.selectedFavoritesPosters, action.poster_id]

        return {
            ...state,
            selectedFavoritesPosters: filteredPosters
        }
    }

    if (action.type === 'REMOVE_SELECTED_FAVORITE_POSTER') {
        const filtered = [...state.selectedFavoritesPosters];
        const filteredPosters = filtered.filter(function(value, index, arr) { return value !== action.poster_id;});

        return {
            ...state,
            selectedFavoritesPosters: filteredPosters
        }
    }

    if (action.type === 'SET_PAGE') {
        return {
            ...state,
            page: action.page
        }
    }

    if (action.type === 'SET_ACTIVE_INDEX') {
        return {
            ...state,
            activeIndex: action.activeIndex
        }
    }

    if (action.type === 'SET_NEW_SEARCH_FLAG') {
        return {
            ...state,
            newSearchFlag: action.newSearchFlag
        }
    }


    if (action.type === 'ADD_SEARCHED_POSTERS') {

        // const newSearchedPosters = [...state.searchedPosters, action.posters]
        const newSearchedPosters = state.searchedPosters.concat(action.posters);

        return {
            ...state,
            searchedPosters: newSearchedPosters,
            hasMore: action.hasMore
        }
    }

    if (action.type === 'REPLACE_SEARCHED_POSTERS') {

        console.log('REPLACED SEARCHED POSTERS: ', action.posters);
        return {
            ...state,
            searchedPosters: action.posters,
            hasMore: action.hasMore
        }
    }


    return state;
};


export default reducer;
