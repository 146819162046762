import React from 'react';
import axios from './../../axios';
import BarChart from './BarChart.js';
import './Statistics.css';
import { withTranslation } from 'react-i18next';


class Statistics extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      likes: [],
      favorites: [],
      daily: [],
      monthly: [],
      poster: null
    }
  }

  getStatistics = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
    };

    const posterId = this.props.location.state.fromOnePoster.id;

    axios.get('/api/v1/statistics/'+posterId, config)
    .then(response => {
      console.log(response);
      this.setState({daily: response.data.statistics.daily});
      this.setState({monthly: response.data.statistics.monthly});
      console.log('after after', this.state);

    }).catch((error) => {


    });

  }

  componentDidMount() {
    this.getStatistics();
  }


  render() {

  const { t } = this.props;


    return (
      <div className="statistics-container">
        <div className="preview-poster">
          <img className="poster-statistics-preview" src={this.props.location.state.fromOnePoster.image}></img>
        </div>
        <div className="chart-container">
          <BarChart data={this.state.daily}  title={t('statistics.last_30_days')}/>
          <BarChart data={this.state.monthly} title={t('statistics.monthly')}/>
        </div>
      </div>
    );
  }
}


export default withTranslation('translations')(Statistics);