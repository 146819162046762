import React, { Component } from 'react';
import Swiper from './../../components/Mosaic/Swiper.js';
import './HomePage.scss';
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/actions";
import { compose } from "redux";
import LazyLoadingGallery from './LazyLoadingGallery.js';

class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state =  {
      'foundImages': null,
      'coordinates': {
        'latitude': null,
        'longitude': null
      },
      'updatedLikes': null
    }
  }

  componentDidUpdate(prevProps, prevState) {

    console.log('did update');
    console.log(prevProps);   console.log(prevState);

       const dataSearch = {
        dateStart: null,
        dateEnd: null,
        tags: [],
        city: null,
        latitude: null,
        longitude: null,
        sort: "distance"
      };

      //get new posters
      this.props.searchPosters(dataSearch, 1, false, true);
    }

  foundImages = (images) => {
    console.log('found images', images);
    this.setState({foundImages: images})
  }

  setCoordinates = (coordinates) => {
    this.setState({coordinates: coordinates})
  }

  handleLikes = (data) => {
    const posterIndex = this.state.foundImages.findIndex(item => item.id === data.posterId);
    // this.useState(foundImages[posterIndex].likes = data.likes);
    this.state.foundImages[posterIndex].likes = data.likes;
    this.forceUpdate();
    this.updateGlobalLikes(data);
  }

  updateGlobalLikes = (data) => {
    this.setState({updatedLikes: data})
  }

  render() {
    return (
      <div className="swiper__container_infinitive">
         {/* <Swiper /> */}

         <LazyLoadingGallery />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchPosters: (data, page, activeIndexFlag, newSearch) =>
      dispatch(
        actionCreators.searchPosters(data, page, activeIndexFlag, newSearch)
      ),
  };
};

export default compose(
  connect(null, mapDispatchToProps)
)(HomePage);


// export default HomePage;
