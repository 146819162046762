import React from "react";
import './AddPoster.css';
import { withTranslation } from 'react-i18next';
import { Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PosterDetails from './PosterDetails.js';
import PosterTemplate from  './../../PosterTemplate/PosterTemplate';
import SelectImage from './SelectImage.js';


class AddPoster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      selectTemplate: false,
      selectedFile: null
    };
  }

  createFromTemplate = () => {
    this.setState({selectTemplate: true});
  }

  selectImage = (image, selectedFile) => {
    console.log('image has been selected', image);
    console.log('selectedFile', selectedFile);

    this.setState({
      image: image,
      selectedFile: selectedFile
    });

  }

  render() {
    if (this.props.propIsLogin !== true) {
      return (
        <Redirect to="/" />
      )
    }

    return (
      <div>
        { this.state.image && this.state.selectedFile ? (
          <PosterDetails image={this.state.image}  selectedFile={this.state.selectedFile} />
        ) : (
          this.state.selectTemplate ?
            <PosterTemplate selectImage={this.selectImage} />
          :
            <SelectImage createFromTemplate={this.createFromTemplate} selectImage={this.selectImage} />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    propIsLogin: state.isLogin
  }
}

export default compose(withTranslation('translations'), connect(mapStateToProps))(AddPoster);
