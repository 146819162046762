import React, { Component } from "react";
import axios from "./../../../axios";
import Autosuggest from "react-autosuggest";
import "./SuggestLocations.scss";
import "./../SearchForm.css";
import { withTranslation, Trans } from "react-i18next";

class SuggestLocations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.location.label ?? "",
      suggestions: [],
      initialValue: "",
      checkerInitialValue: false,
    };
  }

  componentDidMount() {
    if (this.props.initialValue) {
      this.getLocations(this.props.initialValue);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.initialValue) {
      const sug = this.state.suggestions[0];
      if (sug) {
        if (this.state.checkerInitialValue === false) {
          this.setState({ checkerInitialValue: true });
          this.setState({ value: this.props.initialValue });
          this.props.onSubmit(sug.locationId);
        }
      }
    }

    if (this.props.location.label) {
      if (prevProps.location.label != this.props.location.label) {
        this.setState({ ...this.state, value: this.props.location.label });
      }
    }
  }

  getLocations = (value) => {
    console.log("get locations");
    let location = value;
    const APIKEY = "HBLU9uQBMdbJUJUpzYNFouJbBrLVM1lK5D-EmM8pq90";
    let AUTOCOMPLETION_URL =
      "https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json";
    let query = "";
    let params = location.split(" ").join("+");
    if (params.length >= 1) {
      params =
        "?" +
        "query=" +
        params +
        "&maxresults=10" +
        // '&beginHighlight=<b>'+
        // '&endHighlight=</b>'+
        "&apikey=" +
        APIKEY;
    }

    axios.get(AUTOCOMPLETION_URL + params, query).then((response) => {
      this.setState({ suggestions: response.data.suggestions });
      this.props.locationSuggestions(response.data.suggestions);
    });
  };

  escapeRegexCharacters = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  getSuggestionValue = (suggestion) => {
    this.props.locationId(suggestion.locationId);
    return suggestion.label;
  };

  renderSuggestion(suggestion) {
    return "";
  }

  onChange = (event, { newValue, method }) => {
    let locationObject = { label: newValue };
    this.setState({
      location: locationObject,
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.getLocations(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const { t, styleClass, location, locationId, locationSuggestions } =
      this.props;

    const { value, suggestions } = this.state;

    const dValue = value;

    let classes =
      "header-input input-field input-red show-suggestions " +
      this.props.styleClass;
    const inputProps = {
      // className: "form-control input-sm search-form-control",
      className: classes,
      placeholder: t("search_form.type_location"),
      value: this.state.value,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        className="autosuggestInput"
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

export default withTranslation("translations")(SuggestLocations);
