import React, { Component } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { pl, enUS } from 'date-fns/locale';
import i18n from './../../../../containers/i18n';
import { withTranslation } from 'react-i18next';
import './CalendarModal.scss';
import './DateRangeTheme.scss';

//https://github.com/jossmac/react-images
class Calendar extends Component {


  constructor(props) {


    super(props);
    this.state = {
      show: false,
      uploadedFile: null,
      dateRange: {
        startDate: this.props.dateRange.startDate ?? new Date(),
        endDate:  this.props.dateRange.endDate ??   new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        key: 'selection'
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('date component did update');
    console.log(prevProps);
    console.log(prevState);

    // if(this.props.location.label){
    //   if (prevProps.location.label != this.props.location.label) {

    //     this.setState({ ...this.state, value: this.props.location.label });
    //   }
    // }
  }


  componentDidMount() {
    console.log('date mounted');
    console.log(this.state);
    console.log(this.props);

    if (this.props.initialValue) {
      this.getLocations(this.props.initialValue);
    }
  }


  changeDateRange(date){
    console.log('zmiana daty');
    console.log(date);
    console.log(this.state.dateRange);
    
    this.props.changeDateRange(date)
    this.setState({dateRange: date})
  }

  render() {
    return (
      this.props.show ?
        <div className={"calendarmodal " + this.props.styleClass}>
          <DateRange
            editableDateInputs={ true }
            onChange={ item => this.changeDateRange(item.selection) }
            moveRangeOnFirstSelection={ false }
            ranges={ [ this.state.dateRange ] }
            format={ 'Y-MM-dd H:i:s' }
            locale={ i18n.languages[0]=='pl' ? pl : enUS }
          />
        </div>
      : ''
    )
  }
}

const mapStateToProps = state => ( { propIsLogin: state.isLogin } )

export default compose(withTranslation('translations'), connect(mapStateToProps))(Calendar);
