import React from 'react';
import { withTranslation } from 'react-i18next';

class AboutUs extends React.Component
{


    render()
    {

        const { t, i18n } = this.props;


        return(
        <div className="container">
            <h2>
            Zapraszamy do nieograniczonej przestrzeni plakatowych ogłoszeń.
            </h2>   
            <p>
            Naszym celem jest informowanie w prosty i uporządkowany sposób o wydarzeniach w wybranej okolicy, a Ty decydujesz co jest warte zainteresowania.
            </p>
            <p>   
            Wybierz miejsce, termin i zobacz co dzieje się w okolicy. Kumulujemy w jednym miejscu wszystkie wydarzenia sportowe, muzyczne, kulturalne oraz te związane z szeroko pojętą sztuką.
            </p>
            <p>
            Dostępność w przestrzeni plakatu jest nieograniczona. 
            </p>
            <p>    
            W naszej przestrzeni swoje wydarzenie przedstawisz podając datę, miejsce wydarzenia i wrzucisz po prostu plakat.
            </p>
            <p>
            Chcemy inspirować do kreatywnego współuczestnictwa w informowaniu o eventach.
            </p>
            <p>
            Kolorowy świat plakatu będzie nie tylko kalendariusz wydarzeń w prostej i przejrzystej formie, ale z całą pewnością i artystycznym przeżyciem.
            </p>
            <p>
            Naszym naczelnym celem jednak jest <strong>DBANIE O ŚRODOWISKO</strong>. 
            </p>
            <p>
            Nie zużywamy papieru, nie produkujemy śmieci, nie poświęcamy zbędnego czasu na klejenie i sprzątanie plakatów. 
             </p>
            <h3>
            Bądź EKO.
            </h3>
             


        </div>

        )

        
        
    }
 }


export default withTranslation('translations')(AboutUs);