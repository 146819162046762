import React from "react";
import "./Login.scss";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import FacebookLoginWithButton from './../Forms/FacebookLoginWithButton';
import MenuLoggedUser from "../Menu/MenuLoggedUser";
import  "../Menu/MenuLoggedUser.css";

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isLogin: false,
      formLoginError: null,
      showVerified: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);

  }


  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {

    event.preventDefault();
    const dataLogin = {
      email: this.state.email,
      password: this.state.password
    }

    this.props.userLogin(dataLogin);

  }

  handleLogout() {
    this.props.userLogout();
    window.location.assign('/');
  }

  render() {

    if ("#logout" === window.location.hash){
      this.handleLogout();
      window.location.assign('/');
    }

    const { t } = this.props;

    return (

      this.props.propIsLogin ?

        <div className="login login__activemenu">
          <div className="welcome-user">{t('login.welcome')} {this.props.propUser.name}</div>

            {/* <MenuLoggedUser submitLogout={this.handleLogout} /> */}
            <Link to='/'>
            <a href="#" className="menu-item">{t('menu.home')}</a>
            </Link>

            {/* <a id="my-posters" className="menu-item" href="#" onClick={this.handlePosterPanel}>{t('menu.poster_panel')}</a> */}

            <Link to='/my-posters'>
              <a href="#" className="menu-item">{t('menu.my_posters')}</a>
            </Link>
            <Link to='/favorites'>
              <a href="#" className="menu-item">{t('menu.favorites')}</a>
            </Link>

            <Link to='/my-profile'>
              <a href="#" className="menu-item">{t('menu.my_profile')}</a>
            </Link>
            <Link>
              <a id="logout" className="menu-item" href="#" onClick={this.handleLogout}>{t('menu.logout')}</a>
            </Link>
            
          </div>
        :
        <div className="login login__inactivemenu">

          <form className="form-inline" onSubmit={this.handleSubmit}>
            <input autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
              className="form-control mr-sm-2"
              name="email"
              placeholder={t('login.username')}
              aria-label={t('login.username')}
            />

            <input
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
              className="form-control mr-sm-2"
              type="password"
              placeholder={t('login.password')}
              aria-label={t('login.password')}
            />

            <button className="btn btn-viposter"
              type="submit"
              disabled={!this.validateForm()}
              >{t('login.login')}
           </button>


           <Link to='/register'>
              <button className="SignUpButton btn btn-viposter">{t('login.sign_up')}</button>
            </Link>
            <FacebookLoginWithButton/>

           </form>
           <div className="message--alert">
           { this.props.propFormLoginError ? this.props.propFormLoginError : '' }
          </div>
          <div className="message--success">
          { "#verified" === window.location.hash ? t('login.email_verified') : '' }
          </div>


        </div>

    );
  }
}



const mapStateToProps = state => {
  return {
    propIsLogin: state.isLogin,
    propUser: state.user,
    propFormLoginError: state.formLoginError
  }
}

const mapDispatchToProps = dispatch => {
  return {
    userLogin: (dataLogin) => dispatch(actionCreators.login(dataLogin)),
    userLogout: () => dispatch(actionCreators.logout())
  }
}


export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(Login);
