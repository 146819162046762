import React, { Component } from 'react';
import axios from './../../axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp as farThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp as fasThumbsUp } from '@fortawesome/fontawesome-free-regular';
import * as actionCreators from '../../store/actions/actions';
import { connect } from 'react-redux';
import './LikesIcon.css';

class LikesIcon extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isHovered: false,
            isLiked: this.props.propLikedPosters.includes(this.props.image.id),
            posterIndex: this.props.posterIndex
        };
      }



      handleLikePoster(event){

        let likeState = !this.props.propLikedPosters.includes(this.props.image.id);

        const dataPoster = {
            poster_id: this.props.image.id,
            is_liked: likeState,
        }


        //update amount of likes in the source
        let newLikes = 0;
        if(likeState){
            newLikes = this.props.image.likes + 1;
            this.setState({ likes : newLikes});
        } else {
            newLikes = this.props.image.likes - 1;
            this.setState({ likes : newLikes});
        }
        this.setState({isLiked: likeState})

        this.props.onSubmit({
            likes: newLikes,
            posterId: this.props.image.id,
            posterIndex: this.props.posterIndex
        });


        //update posters liked by logged user
        this.props.handleLikedPosters(dataPoster);

        event.preventDefault();


    }


    render() {

        let likeIcon = '';

        if(this.props.propLikedPosters.includes(this.props.image.id)){
            if(this.state.isHovered){
                likeIcon = <FontAwesomeIcon icon={fasThumbsUp} prefix="far" color="#8b49f6" size="2x"  />;
            } else {
                likeIcon = <FontAwesomeIcon icon={farThumbsUp} prefix="far" color="#8b49f6" size="2x"  />;
            }
        } else {
            if(this.state.isHovered){
                likeIcon = <FontAwesomeIcon icon={farThumbsUp} prefix="far" color="#8b49f6" size="2x"  />;
            } else {
                likeIcon = <FontAwesomeIcon icon={fasThumbsUp} prefix="far" color="#8b49f6" size="2x"  />;
            }   
        }

        let likeDiv = <div>
            {likeIcon}
            <label className="label-likes">{this.props.image.likes}</label>
            </div>;

        return (
            <div className='icon' 
            onMouseEnter={() => this.setState({isHovered: true})}
            onMouseLeave={() => this.setState({isHovered: false})}

            onClick={this.handleLikePoster.bind(this)}

            >

            {likeDiv}

            </div>
      )
  }
}
  
const mapStateToProps = state => {
    return {
      propLikedPosters: state.likedPosters
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      handleLikedPosters: (posterData) => dispatch(actionCreators.manageLikedPosters(posterData)),
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(LikesIcon);