import React from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import axios from './../../axios';
import FormErrors from './../../components/FormErrors.js';
import './RegisterForm.css';
import {Route,Link, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';



class RegisterForm extends React.Component
{

    
    constructor(props)
    {
        super(props);

        this.state =
        {
            name: null,
            email: null,
            password: null,
            c_password: null,
            validated: false,
            success: '',
            message: '',
            formErrors: {name: '', email: '', password: '', c_password: ''},
            emailValid: false,
            passwordValid: false,
            confirmPasswordValid: false,
            formValid: false,
            passwordError: '',
            isOpen: false,
            formRegisterError: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    clearFormErrors() {
      this.setState({formErrors: {name: '', email: '', password: '', c_password: ''}});
    }



    handleSubmit(event)
    {

           event.preventDefault();
           event.stopPropagation();

            const registerData = {
              name: this.state.name,
              email: this.state.email,
              password: this.state.password,
              c_password: this.state.c_password

            }
            console.log(registerData);
            this.props.registerUser(registerData);

              
    }


    handleChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      this.setState({
        [name]: value
      });
  }

   hideModal() {
    this.setState({isOpen: false});
  };

  showErrors = (errors) => {

    for (var key in errors) {
        if (!errors.hasOwnProperty(key)) continue;
        return (
          <p>{errors[key][0]}</p>
        )
    }
  
  }

    checkRequired(value) {
        return !!value.trim();
    }


    render()
    {
      const { t } = this.props;

      const isLogged = !!localStorage.getItem('access_token');

      if (isLogged) {
          return (
              <Redirect to="/" />
          )
      }

    
   

        return (
          <div className="container h-100">


        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.isOpen}
          onHide={this.hideModal}>
          <Modal.Header>
          {t('register.contragulations')}
          </Modal.Header>
          <Modal.Body>
              <p>{t('register.you_have_been_registered')}</p>
              <p>
              <Link to='/'>
                 <Button>OK</Button>
              </Link>
              </p>
          </Modal.Body>

        </Modal>
          

<div className="container h-100">
  <div className="row h-100 justify-content-center align-items-center">


  <Form  className="register-form col-5" onSubmit={this.handleSubmit}>
  <h3>{t('register.sign_up')}</h3>
  <Form.Group className="form-group" controlId="formBasicName">
    <Form.Label>{t('register.name')}</Form.Label>
    <Form.Control required value={this.state.name} onChange={this.handleChange} name="name" id="name" type="name" placeholder={t('register.enter_name')} />
  </Form.Group>

  <Form.Group className="form-group" controlId="formBasicEmail">
    <Form.Label>{t('register.email')}</Form.Label>
    <Form.Control required value={this.state.email} onChange={this.handleChange} name="email" id="email" type="email" placeholder={t('register.enter_email')} />
  </Form.Group>

  <Form.Group className="form-group" controlId="formBasicPassword">
    <Form.Label>{t('register.password')}</Form.Label>
    <Form.Control required value={this.state.password}  onChange={this.handleChange} validate={this.checkRequired} type="password" name="password" id="password" placeholder={t('register.enter_password')} />
  </Form.Group>

  <Form.Group className="form-group" controlId="formBasicConfirmPassword">
    <Form.Label>{t('register.confirm_password')}</Form.Label>
    <Form.Control required value={this.state.c_password} onChange={this.handleChange} type="password"  name="c_password" id="c_password" placeholder={t('register.enter_confirm_password')} />
  </Form.Group>


  <div className="panel panel-default">
 <FormErrors formErrors={this.state.formErrors} />
</div>

  <button type="submit"  className="btn btn-viposter btn-block">{t('register.sign_up_button')}</button>
                <p className="forgot-password text-right">
                {t('register.forgot_your_password')} 
                    <Link to={{pathname: "/forgot" }}>
                         <a href="#">{t('register.click_here')}</a>
                     </Link>
                </p>
   <div className="message--alert">
           { this.props.propFormRegisterError ? this.showErrors(this.props.propFormRegisterError) : '' }  
   </div>

</Form>
  </div>
</div>
</div>
        );
    }
 }


 const mapStateToProps = state => {
  return {
    propIsLogin: state.isLogin,
    propUser: state.user,
    propFormRegisterError: state.formRegisterError
  }
}

const mapDispatchToProps = dispatch => {
  return {
    registerUser: (registerData) => dispatch(actionCreators.registerUser(registerData)),
  }
}

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(RegisterForm);