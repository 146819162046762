import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
})

instance.defaults.headers.post['Content-Type'] = 'application/json';
// instance.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
// instance.defaults.headers.post['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';

export default instance;
