import React from "react";
import { withTranslation } from 'react-i18next';
import axios from './../../axios';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './OnePoster.css'
import {Route,Link, Redirect, withRouter} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';


class OnePoster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        is_active: this.props.poster.is_active,
        sweetalert: {
            show: false,
            title: null,
            message: null
          },
        sweetalert_success: {
            show: false,
            title: null,
            message: null
        },
        sweetalert_error: {
            show: false,
            title: null,
            message: null
        }
    };
  }


  activatePoster = () => {

    const pathSetStatus = this.state.is_active ? 'deactivate' : 'activate';

    const posterId = this.props.poster.id;
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      };

    axios.get('/api/v1/'+pathSetStatus+'/'+posterId, config)
    .then(response => {
      let sweetalert = {...this.state.sweetalert_success}

      sweetalert.message= this.state.is_active ? 'Deactivated' : 'Activated';
      sweetalert.title="SUCCESS";
      sweetalert.show=true;
      this.setState({sweetalert_success: sweetalert});
      this.setState({is_active: !this.state.is_active});


    }).catch((error) => {
      let sweetalert = {...this.state.sweetalert_error}

      if(error.request.response){
        const parsedError = JSON.parse(error.request.response);

         let errors = parsedError.error;
        for (var key in errors) {
          if (!errors.hasOwnProperty(key)) continue;
           sweetalert.message=errors[key][0];
            break;
        }
      } else {
        sweetalert.message="Unknown error";
      }
      sweetalert.title = "ERROR";
      sweetalert.show = true;

      this.setState({sweetalert_error: sweetalert})

    });

  }

  showDeletePopup = () => {
      let sweetalert = {...this.state.sweetalert}
      sweetalert.message="Do you really want to remove this poster?";
      sweetalert.title="Are you sure?";
      sweetalert.show=true;
      this.setState({sweetalert: sweetalert});
  }


  deletePoster = () => {
    console.log("poster to remove", this.props.poster);
    this.setState({sweetalert: {show: false }});

    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      };
  
      const posterId = this.props.poster.id;
      axios.delete('/api/v1/poster/'+posterId, config)
      .then(response => {
        let sweetalert = {...this.state.sweetalert_success}

        sweetalert.message="Deleted";
        sweetalert.title="SUCCESS";
        sweetalert.show=true;
        this.setState({sweetalert_success: sweetalert});
      
        this.props.handleRemovePoster(posterId);
  
      }).catch((error) => {
        let sweetalert = {...this.state.sweetalert_error}
  
        if(error.request.response){
          const parsedError = JSON.parse(error.request.response);
  
           let errors = parsedError.error;
          for (var key in errors) {
            if (!errors.hasOwnProperty(key)) continue;
             sweetalert.message=errors[key][0];
              break;
          }
        } else {
          sweetalert.message="Unknown error";
        }
        sweetalert.title = "ERROR";
        sweetalert.show = true;
  
        this.setState({sweetalert_error: sweetalert})
  
      });
  
  
    }


  

  render() {

    const { t } = this.props;


        return (
            <div className="poster-row">

                <div className="poster-column">
                    <img className="thumbnail" src={this.props.poster.thumbnail}/>  
                </div>

                <div className="poster-column manage-section">
                    <div className="poster-column-row poster-activate">
                        <button className={"btn poster-button " +  (this.state.is_active ? "btn-active" : "btn-inactive")  } onClick={this.activatePoster}>
                            {this.state.is_active ? t('one_poster.active') : t('one_poster.inactive')}
                        </button>
                    </div>
                    <div className="poster-column-row poster-info">
                        <p>
                         <strong>{t('one_poster.name')}:</strong> {this.props.poster.name}
                        </p>
                        <p>
                        <strong>{t('one_poster.date')}:</strong>  {this.props.poster.date_start}
                        </p>
                        <p>
                        <strong>{t('one_poster.location')}:</strong>  {this.props.poster.location ? this.props.poster.location[0].fullAddress : '' }
                        </p>
                        <p>
                        <strong>{t('one_poster.likes')}:</strong>  {this.props.poster.likes}
                        </p>
                    </div>
                    <div className="poster-column-row buttons">
                    <Link to={{pathname: "/statistics", state: { fromOnePoster: this.props.poster } }}>
                    <button className="btn btn-statistics poster-button">
                        {t('one_poster.statistics')}
                    </button>
                    </Link>

                    <Link to={{pathname: "/edit-poster", state: { fromOnePoster: this.props.poster } }}>
                        <button className="btn btn-edit poster-button">
                            {t('one_poster.edit')}
                        </button>
                     </Link>

                    <button className="btn btn-delete poster-button" onClick={this.showDeletePopup}>
                        {t('one_poster.delete')}
                    </button>
                    </div>
                </div>



                <SweetAlert
                    warning={true}
                    success={false}
                    showCancel
                    title={this.state.sweetalert.title}
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    onConfirm={this.deletePoster}
                    onCancel={()=>{this.setState({sweetalert: {show: false}})}}
                    focusCancelBtn
                    show={this.state.sweetalert.show}
                    >
                    {this.state.sweetalert.message}
                </SweetAlert>

          
                <SweetAlert 
                    success 
                    title="OK" 
                    show={this.state.sweetalert_success.show}
                    onConfirm={()=>{this.setState({sweetalert_success: {show: false}})}}
                >
                    {this.state.sweetalert_success.message}
                </SweetAlert>

                <SweetAlert 
                    warning 
                    title="Error" 
                    show={this.state.sweetalert_error.show}
                    onConfirm={()=>{this.setState({sweetalert_error: {show: false}})}}
                >
                     {this.state.sweetalert_error.message}
                </SweetAlert>

            </div>
        );
    
  }
}




export default compose(withTranslation('translations'))(OnePoster);