import React from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import './MyProfile.css';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class MyProfile extends React.Component
{
  constructor(props) {
    super(props);

    this.state =
    {
      isLogin: null,
      name: this.props.propUser.name,
      email: this.props.propUser.email,
      new_password: null,
      current_password: null,
      validated: false,
      formErrors: {name: '', email: '', password: '', c_password: ''},
      emailValid: false,
      passwordValid: false,
      confirmPasswordValid: false,
      success: '',
      message: '',
      formValid: false,
      passwordError: '',
      isOpen: false,
      formUpdateUserError: null,
      formUpdateUserSuccess: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  clearFormErrors() {
    this.setState({formErrors: {name: '', email: '', new_password: '', current_password: ''}});
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const userData = {
      name: this.state.name,
      email: this.state.email,
      new_password: this.state.new_password,
      current_password: this.state.current_password
    }

    this.props.editUser(userData);
  }


  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  hideModal() {
    this.props.setFormUpdateUserSuccess(false);
  };

  showErrors = (errors) => {
    for (let key in errors) {
      if (!errors.hasOwnProperty(key)) continue;
      return (
        <p>{errors[key][0]}</p>
      )
    }
  }

  render() {
      // if(this.props.propFormUpdateUserSuccess !=this.state.isOpen){
      //   this.setState({isOpen: this.props.propFormUpdateUserSuccess});
      // }

      const { t } = this.props;


      if (this.props.propIsLogin !== true) {
          return (
              <Redirect to="/" />
          )
      }


        return (
          <div className="container h-100">


        <Modal
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered="true"
          className="centered"
          show={this.props.propFormUpdateUserSuccess}
          onHide={this.hideModal}>
          <Modal.Body>
              <p>{t('my_profile.account_updated')}</p>
              <p>
                 <Button onClick={this.hideModal}>OK</Button>
              </p>
          </Modal.Body>

        </Modal>


<div className="container h-100">
  <div className="row h-100 justify-content-center align-items-center">


  <Form  className="register-form col-5" onSubmit={this.handleSubmit}>
  <h3>{t('my_profile.edit_user')}</h3>
  <Form.Group className="form-group" controlId="formBasicName">
    <Form.Label>{t('my_profile.name')}</Form.Label>
    <Form.Control required value={this.props.propUser.name} onChange={this.handleChange} name="name" id="name" type="name" placeholder={t('my_profile.enter_name')} />
  </Form.Group>

{/*  */}
  <Form.Group className="form-group" controlId="formBasicEmail">
    <Form.Label>{t('my_profile.email')}</Form.Label>
    <Form.Control required value={this.props.propUser.email} onChange={this.handleChange} name="email" id="email" type="email" placeholder={t('my_profile.enter_email')} />
  </Form.Group>

  <Form.Group className="form-group" controlId="formCurrentPassword">
    <Form.Label>{t('my_profile.current_password')}</Form.Label>
    <Form.Control value={this.state.current_password}  onChange={this.handleChange}  type="password" name="current_password" id="current_password" placeholder={t('my_profile.enter_current_password')} />
  </Form.Group>

  <Form.Group className="form-group" controlId="formNewPassword">
    <Form.Label>{t('my_profile.new_password')}</Form.Label>
    <Form.Control value={this.state.new_password} onChange={this.handleChange} type="password"  name="new_password" id="new_password" placeholder={t('my_profile.enter_new_password')} />
  </Form.Group>



  <button type="submit"  className="btn btn-viposter btn-block">{t('my_profile.update')}</button>

   <div className="message--alert">
           { this.props.propFormUpdateUserError ? this.showErrors(this.props.propFormUpdateUserError) : '' }
   </div>

</Form>
  </div>
</div>
</div>
        );
    }
 }


 const mapStateToProps = state => {
  return {
    propIsLogin: state.isLogin,
    propUser: state.user,
    propFormUpdateUserError: state.formUpdateUserError,
    propFormUpdateUserSuccess: state.formUpdateUserSuccess

  }
}

const mapDispatchToProps = dispatch => {
  return {
    editUser: (userData) => dispatch(actionCreators.editUser(userData)),
    setFormUpdateUserSuccess: (showModal) => dispatch(actionCreators.showEditUserModal(showModal))
  }
}

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(MyProfile);
