import React from 'react';
import { Form } from 'react-bootstrap';
import axios from './../../axios';
import FormErrors from './../../components/FormErrors.js';
import './ForgotPassword.css';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';

class Password extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
          name: null,
          email: null,
          password: null,
          c_password: null,
          validated: false,
          success: '',
          message: '',
          formErrors: {name: '', email: '', password: '', c_password: ''},
          emailValid: false,
          passwordValid: false,
          confirmPasswordValid: false,
          formValid: false,
          passwordError: '',
          isOpen: false,
          formRegisterError: null,
          token: (this.props.location.hash).substring(1),
          sweetalert: {
            show: false,
            title: null,
            message: null
          },
          sweetalert_success: {
            show: false,
            title: null,
            message: null
          },
          sweetalert_error: {
            show: false,
            title: null,
            message: null
          }
        };


        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    clearFormErrors() {
      this.setState({ formErrors: {name: '', email: '', password: '', c_password: ''} });
    }

    handleSubmit(event)
    {
      event.preventDefault();
      event.stopPropagation();

      const data = {
        password: this.state.password,
        password_confirmation: this.state.c_password,
        token: this.state.token,
        email: this.state.email
      }

      axios.post('/api/user/password/reset', data)
      .then(response => {
        let sweetalert = {...this.state.sweetalert_success}
        sweetalert.message="New password has been set.";
        sweetalert.title="SUCCESS";
        sweetalert.show=true;
        this.setState({sweetalert_success: sweetalert});
      }).catch((error) => {
        let sweetalert = {...this.state.sweetalert_error}
        if (error.request.response){
          const parsedError = JSON.parse(error.request.response);
            let errors = parsedError.errors;
          for (var key in errors) {
            if (!errors.hasOwnProperty(key)) continue;
              sweetalert.message=errors[key][0];
              break;
          }
        } else {
          sweetalert.message="Unknown error";
        }
        sweetalert.title = "ERROR";
        sweetalert.show = true;
        this.setState({sweetalert_error: sweetalert})
      });
    }


    handleChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      this.setState({
        [name]: value
      });
  }

   hideModal() {
    this.setState({isOpen: false});
  };

  showErrors = (errors) => {
    for (var key in errors) {
        if (!errors.hasOwnProperty(key)) continue;
        return (
          <p>{errors[key][0]}</p>
        )
    }
  }

    checkRequired(value) {
        return !!value.trim();
    }


    render()
    {
      const { t, i18n } = this.props;
      if (this.props.propIsLogin === true) {
        return (
            <Redirect to="/" />
        )
      }

      if (!this.props.location.hash){
        return (
          <Redirect to="/" />
       )
      }


        return (
          <div>


<div className="container h-100">
  <div className="row h-100 justify-content-center align-items-center">


  <Form  className="forgot-password-form col-5" onSubmit={this.handleSubmit}>
  <h3>{t('password.change_password')}</h3>

  <Form.Group className="form-group">
    <Form.Label>{t('password.email')}</Form.Label>
    <Form.Control required value={this.state.email} onChange={this.handleChange} name="email" id="email" type="email" placeholder={t('password.enter_email')} />
  </Form.Group>

  <Form.Group className="form-group">
    <Form.Label>{t('password.password')}</Form.Label>
    <Form.Control required value={this.state.password}  onChange={this.handleChange} validate={this.checkRequired} type="password" name="password" id="password" placeholder={t('password.enter_password')} />
  </Form.Group>

  <Form.Group className="form-group">
    <Form.Label>{t('password.confirm_password')}</Form.Label>
    <Form.Control required value={this.state.c_password} onChange={this.handleChange} type="password"  name="c_password" id="c_password" placeholder={t('password.enter_confirm_password')} />
  </Form.Group>



  <div className="panel panel-default">
 <FormErrors formErrors={this.state.formErrors} />
</div>

  <button type="submit"  className="btn btn-viposter btn-block">{t('password.change_password')}</button>

   <div className="message--alert">
           { this.props.propFormRegisterError ? this.showErrors(this.props.propFormRegisterError) : '' }
   </div>

</Form>
  </div>
</div>


                <SweetAlert
                    success
                    title="OK"
                    show={this.state.sweetalert_success.show}
                    onConfirm={()=>{this.setState({sweetalert_success: {show: false}})}}
                >
                    {this.state.sweetalert_success.message}
                </SweetAlert>

                <SweetAlert
                    warning
                    title="Error"
                    show={this.state.sweetalert_error.show}
                    onConfirm={()=>{this.setState({sweetalert_error: {show: false}})}}
                >
                     {this.state.sweetalert_error.message}
                </SweetAlert>

</div>
        );
    }
 }


 const mapStateToProps = state => {
  return {
    propIsLogin: state.isLogin,
    propUser: state.user,
    propFormRegisterError: state.formRegisterError
  }
}

const mapDispatchToProps = dispatch => {
  return {
    registerUser: (registerData) => dispatch(actionCreators.registerUser(registerData)),
  }
}

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(Password);
