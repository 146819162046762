import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import mobilemenuIcon from "../../../assets/img/icons/menu.svg";
import { Link, withRouter } from "react-router-dom";
import "./MobileMenu.scss";
import flagPL from "../../../assets/img/flags/pl.png";
import flagEN from "../../../assets/img/flags/en.png";
import * as actionCreators from "../../../store/actions/actions";

class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  toggleLanguage = (language) => {
    const { i18n } = this.props;
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    if (i18n.language.includes("pl") && language == "en") {
      changeLanguage(language);
    }

    if (i18n.language.includes("en") && language == "pl") {
      changeLanguage(language);
    }
  };

  toggleMenu = () => this.setState({ show: !this.state.show });

  handleLogout = () => {
    this.props.userLogout();
    window.location.assign("/");
  };

  render() {
    const { i18n, t } = this.props;

    return (
      <div className="mobilemenu__container">
        <div className="mobilemenu" onClick={() => this.toggleMenu()}>
          <img className="headericon" alt="Mobile Menu" src={mobilemenuIcon} />
        </div>
        {this.state.show ? (
          <div className="mobilemenu__menu">
            {this.props.propIsLogin ? (
              <ul className="mobilemenu__list">
                <li>
                  <a
                    className="menu-item"
                    onClick={() => this.toggleLanguage("pl")}
                  >
                    <img className="mobilemenu__flagicon" src={flagPL} />
                  </a>
                  <a
                    className="menu-item"
                    onClick={() => this.toggleLanguage("en")}
                  >
                    <img className="mobilemenu__flagicon" src={flagEN} />
                  </a>
                </li>
                <li>
                  <Link to="/">
                    <a href="#" className="menu-item">
                      {t("menu.home")}
                    </a>
                  </Link>
                </li>
                <li>
                  <Link to="/my-posters">
                    <a href="#" className="menu-item">
                      {t("menu.my_posters")}
                    </a>
                  </Link>
                </li>
                <li>
                  <Link to="/favorites">
                    <a href="#" className="menu-item">
                      {t("menu.favorites")}
                    </a>
                  </Link>
                </li>
                <li>
                  <Link to="/my-profile">
                    <a href="#" className="menu-item">
                      {t("menu.my_profile")}
                    </a>
                  </Link>
                </li>
                <li>
                  <Link>
                    <a
                      id="logout"
                      className="menu-item"
                      href="#"
                      onClick={this.handleLogout}
                    >
                      {t("menu.logout")}
                    </a>
                  </Link>
                </li>
              </ul>
            ) : (
              <ul className="mobilemenu__list">
                <li>
                  <a
                    className="menu-item"
                    onClick={() => this.toggleLanguage("pl")}
                  >
                    <img className="mobilemenu__flagicon" src={flagPL} />
                  </a>
                  <a
                    className="menu-item"
                    onClick={() => this.toggleLanguage("en")}
                  >
                    <img className="mobilemenu__flagicon" src={flagEN} />
                  </a>
                </li>
                <li>
                  <Link to="/login">
                    <a href="#" className="menu-item">
                      {t("menu.login")}
                    </a>
                  </Link>
                </li>
                <li>
                  <Link to="/register">
                    <a href="#" className="menu-item">
                      {t("menu.register")}
                    </a>
                  </Link>
                </li>
              </ul>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ propIsLogin: state.isLogin });

const mapDispatchToProps = (dispatch) => {
  return {
    userLogout: () => dispatch(actionCreators.logout()),
  };
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps)
)(MobileMenu);
