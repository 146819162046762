import React from "react";
import axios from './../../axios';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.
import Autosuggest from 'react-autosuggest';
import theme from "./theme.css";


class AutocompleteTags extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        tags: [], //this.props.tags
        suggestions: []
      }
      this.autocompleteRenderInput = this.autocompleteRenderInput.bind(this);
      this.handleChange = this.handleChange.bind(this);

    }

    componentDidUpdate(prevProps, prevState){



      if(prevProps.tags.length != prevState.tags.length){
        if (prevProps.tags) {
          if(this.state.tags.length!=prevProps.tags.length){
             this.setState({ ...this.state, tags: prevProps.tags});
          }
        }
      }

    }



  
    handleChange (tags, tag) {

      if(tags.indexOf(tag[0]) > -1){

        //if tag already exist (duplicate) then doesn't update array
        let tagExists = this.state.tags.indexOf(tag[0]) > -1;
        if(tagExists === false){
          this.setState({...this.state, tags: tags})
          this.props.onChangeTags(tags);  
        }
      } else {

        //if tag doesnt exist then remove/add to array and update
        this.setState({...this.state, tags: tags})
        this.props.onChangeTags(tags);  
      }



    }
  

    autocompleteRenderInput ({addTag, ...props}) {


      const handleOnChange = (e, {newValue, method}) => {
        let value = '';
        
      if(newValue && newValue.length>0){
        //get all categories
        axios.get('/api/v1/tags/search/'+newValue)
        .then(response => {
          const suggestions = [];
          response.data.map(obj=> {
            suggestions[obj.id] = obj;
          })
           this.setState({ ...this.state, suggestions: suggestions});
        });
      }


        if (method === 'enter') {
            e.preventDefault();
        } else {
            props.onChange(e);
        }
        
      }

      const inputValue = (props.value && props.value.trim().toLowerCase()) || ''
      const inputLength = inputValue.length

  
      return (  
        <Autosuggest
          ref={props.ref}
          suggestions={this.state.suggestions}
          shouldRenderSuggestions={(value) => value && value.trim().length > 0 && this.state.suggestions.length>0}
          getSuggestionValue={(suggestion) => suggestion ? suggestion.name : ''}
          renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
          inputProps={{...props, onChange: handleOnChange, placeholder: "TAG" }}
          onSuggestionSelected={(e, {suggestion}) => {
            addTag(suggestion.name)
          }}
          onSuggestionsClearRequested={() => {}}
          onSuggestionsFetchRequested={() => {}}
        />
      )
    }


    render () {
      return <TagsInput renderInput={this.autocompleteRenderInput} value={this.state.tags} onChange={this.handleChange} />
    }
  }
  
  export default AutocompleteTags