import React from 'react';
import {Route,Link, Redirect} from 'react-router-dom';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
import './PosterTemplate.css';
import TemplateEditor from './TemplateEditor.js';
import AllTemplates from './AllTemplates.js';
import axios from './../../axios';
import * as actionCreators from '../../store/actions/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './PosterTemplate.css';

class PosterTemplate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      clickSave: {
        type: '',
        number: 0
      },
      redirect: false,
      savedImage: null,
      newTemplate: false,
      posterTemplate: {
        
      }

    }
    this.handleSavePoster = this.handleSavePoster.bind(this);
    this.handleSaveTemplate = this.handleSaveTemplate.bind(this);

  }

//http://nhn.github.io/tui.image-editor/latest/ImageEditor#event-addText


  myTheme = {
    // Theme object to extends default dark theme.
  };


  loadPosterTemplate = (data) => {
      this.setState({posterTemplate: data});
   }

  storePosterTemplate = (image, imageType) => {
    if(imageType=='template') {
      console.log('save poster image', image);
      console.log('save poster image type', imageType);

    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
    };


    const formData = new FormData();
    formData.append('image', image);

    axios.post('/api/v1/poster-template', formData, config)
    .then(response => {
      this.setState({newTemplate: image});


      // if(imageType=='poster'){
      //   console.log('here');
      //   //redirewct to add poster with new image
      //   // return <Redirect to={{pathname: "/add-poster", state: { fromPosterTemplate: image } }} />
      //   this.setState({redirect: true});


      // }

    }).catch((error) => {


    });
  } else {

  
  let scope = this;
  //Usage example:
  this.urltoFile(image, 'name.png','image/png')
  .then(function(file){ 


    const blob = scope.b64toBlob(image, 'image/png');
    const blobUrl = URL.createObjectURL(blob);
    //convert image to blob
    scope.props.selectImage(blobUrl, file);



  });  
   
  // console.log('from file', rr);

  

    }  }

  urltoFile(url, filename, mimeType){
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
  }
  
  b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    // b64Data = b64Data.split(',')[1];
    b64Data = b64Data.substr(b64Data.indexOf(',') + 1);

    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
  handleSavePoster(){
    this.setState({clickSave: {type: 'poster', number: this.state.clickSave.number++}});
  }

  handleSaveTemplate(){
    this.setState({clickSave: {type: 'template', number: this.state.clickSave.number++}});
  }

  render() {

  const { t } = this.props;


  if (this.props.propIsLogin !== true) {
    return (
        <Redirect to="/" />
    )
  }

    if(this.state.redirect){
        return <Redirect to={{pathname: "/add-poster", state: { fromPosterTemplate: this.state.savedImage } }} />

    }

    return (
      <div>
        <div className="header-buttons">
          <button className="btn btn-viposter poster-button" onClick={this.handleSavePoster}>{t('templates.save_as_poster')}</button>

          <button className="btn btn-viposter poster-button" onClick={this.handleSaveTemplate}>{t('templates.save_as_template')}</button>
        </div>


        <div className="poster-editor">
          <div className="template-editor">
            <TemplateEditor saveImage={this.storePosterTemplate} clickSave={this.state.clickSave} loadedPosterTemplate={this.state.posterTemplate}/>
          </div>
          <div className="all-templates">
            <AllTemplates loadPosterTemplate={this.loadPosterTemplate} newTemplate={this.state.newTemplate} />
          </div>
        </div>

      </div>
    );
  }
}



const mapStateToProps = state => {
  return {
    propIsLogin: state.isLogin,
  }
}



export default compose(withTranslation('translations'), connect(mapStateToProps))(PosterTemplate);