import React from 'react';
import './Swiper.scss';
import './Mosaic.css';
import 'swiper/swiper-bundle.css';

import Swiper from 'react-id-swiper';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import { compose } from 'redux';
import Posters from './Posters';
import ModalPoster from './ModalPoster';

import InfiniteScroll from 'react-infinite-scroller';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class SimpleSwiper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: this.props.activeIndex,
      searchedPosters: this.props.searchedPosters ?? [],
      page: this.props.page,
      newSearchFlag: this.props.newSearchFlag,
      isOpen: false,
      displayedImage: {},
      hasMoreImages: true
      // activeSlideKey: 0
      //  newSearchFlag: this.props.newSearchFlag
    }

    this.swipeNext = this.swipeNext.bind(this)
    this.swipePrev = this.swipePrev.bind(this)
    this.onePosterClick = this.onePosterClick.bind(this);
    this.onSubmitLikes = this.onSubmitLikes.bind(this);

  }

  swipeRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    return props;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {


    // if(this.props.newSearchFlag == true) {
    //   const swiper = this.swipeRef.current.swiper;

    //   console.log("swiper", swiper);
    //   //  try to rebuild the swiper
    //   //  this.setState({activeSlideKey: 0});
    //   //  this.props.setNewSearchFlag(false);
    // }
  }

    swipePrev() {
      // const swiper = this.swipeRef.current.swiper;

      // if (swiper !== null ) {
      //   swiper.slidePrev();
      // }
    }


    swipeNext() {
      const swiper = this.swipeRef.current.swiper;
      const activeIndex = swiper.realIndex;

      if( activeIndex + 2 >= this.state.searchedPosters.length){
        this.props.setPage(this.state.page+1);
      }

      if (swiper !== null) {
        swiper.slideNext();
      }

    }

    onePosterClick(poster) {
      console.log('poster clicked');
      console.log(this.state.isOpen);
      this.setState({displayedImage: poster, isOpen: true});
    }

    //immidiately show correct number of likes
    onSubmitLikes(data) {
      this.state.searchedPosters.map((postersGroup, postersGroupIndex) => {
        postersGroup.map((onePoster, onePosterIndex) => {
          if(onePoster.id == data.posterId){
            this.state.searchedPosters[postersGroupIndex][onePosterIndex].likes = data.likes;
          }
        })
      });
    }

    swipePrevButton = () => {
      console.log('swipe prev button')
    }
    swipeNextButton = () => {
      console.log('swipe prev button')
    }

    onSlideChange = () => {
      const swiper = this.swipeRef.current.swiper;
      const activeIndex = swiper.realIndex;
      console.log('slide change ', activeIndex)

      if( activeIndex + 2 >= this.state.searchedPosters.length){
        console.log('propsuj');
        this.props.setPage(this.state.page+1);
      }
    }

    loadMore = (page) => {
      console.log('load more'+page);
      this.props.setPage(page);
      if(page>=5){
        this.setState({hasMoreImages: false});

      }
    }



  render() {
    const SwipePostersDiv = this.state.searchedPosters
      .map((results, index) => {
        return (
          <div
            className="swipe-posters"
            key={ index }
          >
            <Posters
              posters={ results }
              onPosterClick={ this.onePosterClick }
            />
          </div>
        )
    });

    const params = {
      on: {
        slideChange:this.onSlideChange
      }
    };

  return (
    <div>
      <ModalPoster
        isOpen={this.state.isOpen}
        displayedImage={this.state.displayedImage}
        propIsLogin={this.props.propIsLogin}
        onSubmitLikes={this.onSubmitLikes}
      />
      {/* react carousel: 
      https://www.npmjs.com/package/react-responsive-carousel */}
      {/* <Carousel
        axis='vertical'
        centerMode={true}
        verticalSwipe='standard'
        autoPlay={true}
      >
        {SwipePostersDiv}

      </Carousel> */}
<div style={{height:"700px",overflow:"auto"}}>
    <InfiniteScroll
        pageStart={0}
        loadMore={this.loadMore}
        hasMore={this.hasMoreImages}
        loader={<div className="loader" key={0}>Loading ...</div>}
        threshold={250}
        useWindow={false}
        
    >
        {SwipePostersDiv}
    </InfiniteScroll>
    </div>


       {/* {SwipePostersDiv} */}
      {/* /* <Swiper
        ref={this.swipeRef}
        lazy={true}
        shouldSwiperUpdate={true}
        // rebuildOnUpdate={true}
        activeSlideKey={this.state.activeSlideKey}
        // renderPrevButton={this.swipePrevButton}
        // renderNextButton={this.swipeNextButton}
        {...params}

      >
       
      </Swiper> */ }
      {/* <button onClick={this.swipePrev}>Prev 2</button>
      <button onClick={this.swipeNext}>Next</button> */}
    </div>
  );

  }
}

const mapStateToProps = state => {
  return {
    page: state.page,
    activeIndex: state.activeIndex,
    searchedPosters: state.searchedPosters ?? [],
    newSearchFlag: state.newSearchFlag,
    propIsLogin: state.isLogin
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setPage: (page) => dispatch(actionCreators.setPage(page)),
    setActiveIndex: (index) => dispatch(actionCreators.setActiveIndex(index)),
    setNewSearchFlag: (flag) => dispatch(actionCreators.setNewSearchFlag(flag))
  }
}

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
)(SimpleSwiper);
