import React from "react";
import axios from './../../../axios';
import './AddPoster.css';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './../ReactTags.css';
import { withTranslation } from 'react-i18next';
import SuggestLocations from './../Inputs/SuggestLocations.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareUp as faUpload } from '@fortawesome/fontawesome-free-regular';
import SweetAlert from 'sweetalert2-react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {pl, enUS} from 'date-fns/locale';
import i18n from './../../../containers/i18n';
import Categories from './../Inputs/Categories';
import AutocompleteTags from './AutocompleteTags';


//issue of locale:
// https://github.com/date-fns/date-fns/issues/1833

class PosterDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: this.props.selectedFile,
      uploadedFile: this.props.image,
      dateRange: {
        startDate: new Date(),
        endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        key: 'selection'
      },
      categories: [],
      categoryTags: [],
      latitude: null,
      longitude: null,
      address: {},
      name: null,
      redirectUrl: null,
      sweetalert: {
        show: false,
        title: null,
        message: null
      },
      tags: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.convertBlobToBase64 = this.convertBlobToBase64.bind(this);

  }

  convertBlobToBase64(){

    var blob;

    var oReq = new XMLHttpRequest();
oReq.open("GET", this.props.image, true);
oReq.responseType = "arraybuffer";

oReq.onload = function() {
   blob = new Blob([oReq.response], {type: "image/png"});
};


    oReq.send();
    console.log(blob);
    this.setState({selectedFile: blob});

  }



//   componentDidMount(){
//     console.log('sprawdzanie', this.props.event)
//     console.log('unsafe component did mount', this.state);

// // console.log('sprawdzanie target', this.props.event.currentTarget.files[0])
//     // this.convertBlobToBase64();
//   }


  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
}

  UNSAFE_componentWillMount(){

    console.log('unsafe component will mount', this.state);

    //get all categories
    axios.get('/api/v1/categories')
    .then(response => {
      console.log(response);
       const categories = [];
      response.data.map(obj=> {
        categories[obj.id] = obj;
      })
       this.setState({ categories: categories});
    });
  }

  submitFormHandler = () => {
    const searchedCategories = [];
    this.state.categoryTags.map(obj=> {
      searchedCategories.push(obj.id);
    })


    const formData = new FormData();
    formData.append('date_start', this.state.dateRange.startDate);
    formData.append('date_end', this.state.dateRange.endDate);
    formData.append('categories', searchedCategories);
    formData.append('language', this.props.i18n.languages[0]);
    if(this.state.latitude) formData.append('latitude', this.state.latitude);
    if(this.state.longitude) formData.append('longitude', this.state.longitude);
    if(this.state.name) formData.append('name', this.state.name);
    if(this.state.redirectUrl) formData.append('redirect_url', this.state.redirectUrl);
    if(this.state.tags) formData.append('tags', this.state.tags);


    // const picture = {
    //     image: this.state.uploadedFile,
    //     name: 'image',
    //     size: this.state.uploadedFile.length,
    //     type: 'img/png'
    //   }
    console.log('submit form handler', this.state);

    formData.append('image', this.state.selectedFile);


    formData.append('country', this.state.address.country ? this.state.address.country  : '');
    formData.append('city', this.state.address.city  ? this.state.address.city  : '');
    formData.append('state', this.state.address.state  ? this.state.address.state  : '');
    formData.append('postal_code', this.state.address.postalCode  ? this.state.address.postalCode  : '');
    formData.append('street', this.state.address.street  ? this.state.address.street  : '');
    formData.append('house_number', this.state.address.houseNumber  ? this.state.address.houseNumber  : '');
    formData.append('full_address', this.state.address.label  ? this.state.address.label  : '');



    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
    };

    axios.post('/api/v1/poster', formData, config)
    .then(() => {
      let sweetalert = {...this.state.sweetalert}

      sweetalert.message=i18n.t('add_poster.poster_has_been_added');
      sweetalert.title=i18n.t('global.success');
      sweetalert.show=true;
      this.setState({sweetalert: sweetalert});


    }).catch((error) => {
      let sweetalert = {...this.state.sweetalert}

      if(error.request.response){
        const parsedError = JSON.parse(error.request.response);

         let errors = parsedError.error;
        for (var key in errors) {
          if (!errors.hasOwnProperty(key)) continue;
           sweetalert.message=errors[key][0];
            break;
        }
      } else {
        sweetalert.message=i18n.t('global.unknown_error');
      }
      sweetalert.title = i18n.t('global.error');
      sweetalert.show = true;

      this.setState({sweetalert})

    });


  }

  onChangeHandler=event=>{
    this.setState({
      selectedFile: event.target.files[0],
      uploadedFile: URL.createObjectURL(event.target.files[0]),
      loaded: 0,
    })
  }


  handleChangeTags = (tags) => {
    this.setState({tags});
    console.log('handle tags: ', this.state.tags);
  }



  // handleDelete (i) {
  //   const categoryTags = this.state.categoryTags.slice(0)
  //   categoryTags.splice(i, 1)
  //   this.setState({ categoryTags })
  // }

  // handleAddition (categoryTag) {
  //   const categoryTags = [].concat(this.state.categoryTags, categoryTag)
  //   this.setState({ categoryTags })
  // }



handleLocation = (event) => {

  let location = event.target.value;
  const APIKEY = 'HBLU9uQBMdbJUJUpzYNFouJbBrLVM1lK5D-EmM8pq90';
  let AUTOCOMPLETION_URL = 'https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json';


  let query = '';
  let params = location.split(' ').join('+');
  if (params.length >= 1){
       params = '?' +
          'query='+params+
          '&maxresults=5' +
          '&apikey=' + APIKEY;
  }

axios.get(AUTOCOMPLETION_URL+params, query)
  .then(response => {
    this.setState({suggestions: response.data.suggestions})
  });
}


getCoordinates = (locationId) => {

  const APIKEY = 'HBLU9uQBMdbJUJUpzYNFouJbBrLVM1lK5D-EmM8pq90';

  let URL = 'https://geocoder.ls.hereapi.com/6.2/geocode.json';
  let params = '?locationid=' + locationId + '&jsonattributes=1&gen=9&apiKey=' + APIKEY;

  console.log('get coordinates');
  axios.get(URL+params)
  .then(response  => {
    let displayPosition = response.data.response.view[0].result[0].location.displayPosition;
    this.setState({address: response.data.response.view[0].result[0].location.address});
    console.log('address', this.state.address);
    this.setState({latitude: displayPosition.latitude, longitude: displayPosition.longitude});
  });
}


handleLocationSuggestions = (locationId) => {
  console.log('handleLocationSuggestions');
  this.getCoordinates(locationId);
}

onChangeCategories = (event, value) => {
  this.setState({ categoryTags: value })
}

  render() {




    const { t, i18n } = this.props;
    if (this.props.propIsLogin !== true) {
      return (
          <Redirect to="/" />
      )
    }


    return (
        <div className="container">
            <div className="h2-title">
              <h2>{t('add_poster.add_your_new_poster')}</h2>
            </div>
            <div className="add-poster-form">
            <div className="add-poster-column">
              <div className="add-poster-row">
              <div className="row-left">
              {t('add_poster.name')}:
              </div>
              <div className="row-right">
              <input className="form-control input-sm" type="text" name="name" placeholder={t('add_poster.name')} value={this.state.name} onChange={this.handleChange} />
              </div>
              <div className="row-left">
              {t('add_poster.location')}:
              </div>
              <div className="row-right show-suggestions">
              <SuggestLocations locationId={this.handleLocationSuggestions} />
              </div>
              <div className="row-left">
              {t('add_poster.redirect_url')}:
              </div>
              <div className="row-right">
              <input className="form-control input-sm" type="text" name="redirectUrl" placeholder={t('add_poster.redirect_url')} value={this.state.redirectUrl} onChange={this.handleChange} />
              </div>
              <div className="row-left">
              {t('add_poster.upload_image')}:
              </div>
              <div className="row-right">

              <label htmlFor="upload-button">
                {this.state.uploadedFile ? (
                  <img className="uploaded-file" src={this.state.uploadedFile} alt="uploaded image" />
                ) : (
                <div>
                  <FontAwesomeIcon icon={faUpload} prefix="far" color="#3fbac2" size="3x"  />
                </div>
                 )}
             </label>

              <input type="file" id="upload-button" name="file" style={{ display: "none" }} onChange={this.onChangeHandler}/>

              </div>
              <div className="row-left">
              {t('add_poster.categories')}:
              </div>
              <div className="row-right">
              <Categories value={this.state.categoryTags} categories={this.state.categories} language={i18n.languages[0]} handleChangeAutocomplete={this.onChangeCategories} />
          </div>

          <div className="row-left">
              {t('edit_poster.tags')}:
              </div>
              <div className="row-right">
              <AutocompleteTags onChangeTags={this.handleChangeTags} tags={this.state.tags}  />
          </div>

        </div>
         </div>
            <div className="add-poster-column">
              <DateRange
                editableDateInputs={true}
                onChange={item => this.setState({dateRange: item.selection})}
                moveRangeOnFirstSelection={false}
                ranges={[this.state.dateRange]}
                format={'Y-MM-dd H:i:s'}
                locale={ i18n.languages[0]=='pl' ? pl : enUS }
              />
            </div>
          </div>
        <div className="upload-section">
            <button type="button" className="btn btn-viposter btn-block upload-button" onClick={this.submitFormHandler}>{t('add_poster.add_poster')}</button>
        </div>


        <SweetAlert
        show={this.state.sweetalert.show}
        title={this.state.sweetalert.title}
        text={this.state.sweetalert.message}
        onConfirm={() => this.setState({sweetalert: {show: false }})}
        />

        </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    propIsLogin: state.isLogin
    }
}

export default compose(withTranslation('translations'), connect(mapStateToProps))(PosterDetails);
