import React, { useState } from 'react';
import axios from './../../axios';
import { render } from 'react-dom';
import {Route,Link, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import FavGallery from './FavGallery.js';
import './Favorites.css';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';


class Favorites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      showRemoveButton: false
    }
    this.removeSelected = this.removeSelected.bind(this);
  }

 formatDate (input) {
    var datePart = input.match(/\d+/g),
    year = datePart[0].substring(2), // get only two digits
    month = datePart[1], day = datePart[2];
  
    return day+'/'+month+'/'+year;
  }
  componentDidMount(){
    console.log("DID MOUNT");
    this.getFavoritesPosters();
  };



  getFavoritesPosters = () => {
   
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
    };
    axios.get('/api/user/favorites', config)
    .then(response => {
      const images = [];
      response.data.map(obj=> {
        obj.src = obj.image;
        obj.width = 100;
        obj.height = 140;
        images.push(obj);
      })
      
      this.setState({ images: images});
    });
  }

  
  removeSelected(){

    //immidiately remove images
    var temp = [];
    this.props.propSelectedFavoritesPosters.forEach(function (k, i) {
        temp[k] = 1;
    });

    const filteredPosters = this.state.images.filter(function(item){ 
      return !temp[item.id]
    }, this);

   this.setState({images: filteredPosters});

   //update data in redux
    this.props.propSelectedFavoritesPosters.forEach(function(poster_id) {
    const dataPosters = {
      is_favorite: false,
      poster_id: poster_id
    }
    this.props.removeFavoritesPosters(dataPosters)
  }, this); 


  }


  styleSmall(){
    return ({
      //  border:'5px solid red'
      width: '128px',
      height: '180px'
       
    });
 }



  render() {

    const { t } = this.props;


    // if(this.props.propSelectedFavoritesPosters.length!=this.state.images.length){
    //   this.getFavoritesPosters();
    // }

    if (this.props.propIsLogin !== true) {
      return (
          <Redirect to="/" />
      )
    }

    if (typeof this.props.propSelectedFavoritesPosters !== 'undefined' && 
    this.props.propSelectedFavoritesPosters.length > 0) {
      if(this.state.showRemoveButton!=true){
        this.setState({showRemoveButton: true});
      }
    } else {
      if(this.state.showRemoveButton!=false){
        this.setState({showRemoveButton: false});
      }
    }
    



    return ( <div className="favorites-div container">
      <h2>{t('favorites.your_favorite_posters')}</h2> 
      {this.state.showRemoveButton ?
      <button  className="btn btn-viposter"  onClick={this.removeSelected}>{t('favorites.remove_selected')}</button> : ''}
      <FavGallery images={this.state.images}></FavGallery>
    </div>)
  }

}


const mapStateToProps = state => {
  return {
    propIsLogin: state.isLogin,
    propSelectedFavoritesPosters: state.selectedFavoritesPosters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeFavoritesPosters: (posterData) => dispatch(actionCreators.manageFavoritesPosters(posterData))
  }
}


export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps))(Favorites);