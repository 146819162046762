import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import './TagsModal.scss';
import i18n from './../../../../containers/i18n';
import axios from './../../../../axios';

class TagsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      selectedCharacter: '',
      popularTags: [],
      tags: this.props.tags
    }
  }

  componentDidMount(){
    this.getMostPopularTags('');
  }

  getMostPopularTags = (letter = '') => {
   
    axios.get('/api/v1/tags/popular'+(letter ? '/'+letter : ''))
    .then(response => {
      const tags = [];
      response.data.map(obj=> {
        tags.push(obj);
      })
      
      this.setState({ popularTags: tags});
    });
  }


  characters = {
    pl: [
      'a', 'b', 'c', 'ć', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'ł', 'm', 'n', 'o', 'ó', 'p', 'q', 'r', 's', 'ś', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'ź', 'ż',
    ],
    en: [
      'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
    ],
  }

  onCharacterClicked = (character) => {


      if(this.state.selectedCharacter === character){
        this.setState({ selectedCharacter: '' })
      } else {
        this.setState({ selectedCharacter: character })
        this.getMostPopularTags(character);
      }
  }

  generateCharacterElements = (locale) => {
    const cssClass = 'character__wrapper'
    const characterElement = (character) =>
      <span
        className={
          `${ cssClass } ${ character === this.state.selectedCharacter ? `${ cssClass }--selected` : '' }`
        }
        key={ character }
        onClick={ () => this.onCharacterClicked(character) }
      >
        <span
          className="character"
        >{ character }</span>
      </span>

    return this.characters[locale]
      .map(character => characterElement(character))
  }

  onTagClick = (tag) => {


    let tagExists = false;
    if(this.state.tags.length>0){
      tagExists = this.state.tags.indexOf(tag.name) > -1;
    }

    if(tagExists === false) {
      this.setState({ ...this.state, tags: [...this.state.tags, tag.name] });
    }

  }


  componentDidUpdate(prevProps, prevState){

    if(this.props.tags.length != prevProps.tags.length){
      this.setState({ ...this.state, tags: this.props.tags});
    } else {
      this.props.onSubmit(this.state.tags);
    }
    
  }

  generateTags = (locale) => {

    const tagElement = (tag) =>
      <span
        className="tag"
        key={ tag.name }
        onClick={ () => this.onTagClick(tag) }
      >{ tag.name }</span>

    return this.state.popularTags.map(tag => tagElement(tag))
  }

  render() {
    const locale = i18n.language
    return (
      this.props.show ?
      
        <div className={"tagsmodal " + this.props.styleClass}>
          <div className="tagsmodal__characters">
            { this.generateCharacterElements(locale) }
          </div>
          <div className="tagsmodal__tags">
            { this.generateTags(locale) }
          </div>
        </div>
        : ''
    )
  }
}

const mapStateToProps = state => ({ propIsLogin: state.isLogin })

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps)
)(TagsModal);
